import gsap from 'gsap';

export function round(num: number, precision: number = 0) {
  if (precision < 0) precision = 0;
  return Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);
}

export function isSSR() {
  return typeof window === 'undefined' || typeof document === 'undefined';
}

export function isNumber(value: any) {
  return typeof value === 'number' && !isNaN(value);
}

export function sleep(ms: number, ignoreGlobalTimeline = false, timeline?: gsap.core.Timeline) {
  return new Promise<any>((resolve) => {
    if (ignoreGlobalTimeline) {
      setTimeout(resolve, ms);
    } else {
      const delay = ms / 1000;

      if (timeline) {
        timeline.add(gsap.delayedCall(delay, resolve), timeline.time() + delay);
      } else {
        gsap.delayedCall(delay, resolve);
      }
    }
  });
}

export function getCookie(name: string) {
  const parts = `; ${document.cookie}`.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() ?? '';
}

export function isValidEmail(email: string, maxLength = 200) {
  return typeof email === 'string' && email.length <= maxLength && /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i.test(email);
}
