import React from 'react';
import styles from './Line.module.scss';
import classNames from 'classnames';

export default function Line({
  className,
  direction = 'horizontal',
  style = {},
  color,
  isSolid,
  isDark,
  strokeDashArray = '6 6',
  strokeWidth = 2,
  retinaStrokeWidth = strokeWidth - 0.5,
  ...restProps
}: LineProps) {
  const isVertical = direction === 'vertical';
  const halfStrokeWidth = strokeWidth / 2;
  const strokeStyle = strokeWidth !== 2 ? `${strokeWidth}px` : undefined;

  const retinaStrokeStyle =
    retinaStrokeWidth && strokeStyle ? `${retinaStrokeWidth}px` : undefined;

  return (
    <svg
      viewBox={`0 0 ${strokeWidth} ${strokeWidth}`}
      preserveAspectRatio="none"
      style={{
        '--stroke-width': strokeStyle,
        '--retina-stroke-width': retinaStrokeStyle,
        ...style
      }}
      className={classNames(
        'line',
        styles.line,
        isVertical ? styles.vertical : styles.horizontal,
        className,
        {
          [styles.dark]: isDark
        }
      )}
      {...restProps}
    >
      <line
        x1={isVertical ? halfStrokeWidth : 0}
        y1={isVertical ? 0 : halfStrokeWidth}
        x2={isVertical ? halfStrokeWidth : strokeWidth}
        y2={isVertical ? strokeWidth : halfStrokeWidth}
        stroke={color}
        strokeDasharray={isSolid ? undefined : strokeDashArray}
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}

type LineProps = SVGProps & {
  className?: string;
  direction?: 'horizontal' | 'vertical';
  color?: string;
  isSolid?: boolean;
  isDark?: boolean;
  strokeDashArray?: string;
  strokeWidth?: number;
  retinaStrokeWidth?: number;
};
