import React, { useState, useRef } from 'react';
import styles from './Integrations.module.scss';
import PlayIcon from './play.svg';
import { ArrowButton } from 'www/components/Button';
import classNames from 'classnames';

import videoUrl from 'www/videos/partner-integrations.mp4';
import poster from 'www/videos/partner-integrations-poster.webp';

export default function Integrations() {
  const [hasPlayed, setHasPlayed] = useState(false);
  const video = useRef<HTMLVideoElement>(null);

  return (
    <section className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.text}>
          <h2>Get more value with Rhino integrations</h2>
          <p className="text-intro">
            Rhino works with your PMS - Yardi, RealPage, MRI, Entrata, Appfolio,
            Resman, and more.
          </p>
          <p>
            Renters enroll within your current leasing flow. We push the data
            wherever your teams work.
          </p>
        </div>
      </div>

      <div
        className={classNames(styles.videoWrapper, {
          [styles.played]: hasPlayed
        })}
      >
        <button
          className={styles.play}
          onClick={() => {
            setHasPlayed(true);
            video.current?.play();
          }}
        >
          <span className="sr">Play video</span>
          <PlayIcon />
        </button>
        <div className={styles.caption}>
          <span className={styles.captionLabel}>Watch</span>
          <strong>See how Rhino works with your PMS</strong>
        </div>
        <video
          ref={video}
          className={styles.video}
          src={videoUrl}
          poster={poster}
          controls={hasPlayed}
        />
      </div>
    </section>
  );
}
