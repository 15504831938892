import React from 'react';
import { useState, useEffect, useId, useRef, useCallback } from 'react';
import styles from './HowItWorks.module.scss';
import { Section, Content } from 'www/components/layout';
import { MemoizedBodyText } from 'www/components/BodyText';
import LottieAnimation, {
  type AnimationControls,
  type AnimationFont
} from 'www/components/LottieAnimation';
import useVariableRef from 'www/hooks/useVariableRef';
import Line from 'www/components/Line';
import Progress from './progress.svg';
import classNames from 'classnames';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import BezierEasing from 'bezier-easing';
// import animationData from './how-it-works.json';
import Poster from './poster.svg';

export default function HowItWorks({ startIndex = 0 }: HowItWorksProps) {
  const list = useRef<HTMLUListElement>(null);
  const id = useId();
  const [activeIndex, setActiveIndex] = useState(startIndex);
  const activeIndexRef = useVariableRef(activeIndex);
  const [isReady, setIsReady] = useState(false);
  const animation = useRef<AnimationControls>(null);
  const markers = useRef<AnimationMarkers>({});
  const isLooping = useRef(false);
  const userManuallyNavigated = useRef(false);

  useGSAP(
    () => {
      gsap.set(`.${styles.description}`, {
        height: i => (i === startIndex ? 'auto' : '0')
      });
    },
    { scope: list }
  );

  useGSAP(
    () => {
      gsap.to(`.${styles.description}`, {
        height: i => (i === activeIndex ? 'auto' : '0'),
        duration: 0.4,
        ease: listEase,
        autoRound: false,
        onComplete() {
          if (!isLooping.current) animation.current?.play();
        }
      });
    },
    { scope: list, dependencies: [activeIndex] }
  );

  const handleReady = useCallback(() => {
    if (!animation.current) return;

    const instance = animation.current.player?.getAnimationInstance();
    if (!instance) return;

    instance.markers.forEach(({ time, duration, payload }) => {
      markers.current[payload.name] = {
        startFrame: time,
        duration
      };
    });

    setIsReady(true);
  }, []);

  const handleFrame = useCallback(
    (frame: number) => {
      if (!list.current || isLooping.current) return;
      const marker = markers.current[items[activeIndexRef.current].marker];
      if (!marker) return;

      const { startFrame, duration } = marker;

      // The animation has looped back to zero
      if (frame < startFrame) {
        if (!userManuallyNavigated.current) {
          isLooping.current = true;
          list.current.style.setProperty('--progress', '0');
          animation.current?.pause();
          activeIndexRef.current = 0;
          setActiveIndex(0);
        }

        return;
      }

      // The segment has completed, move to next step
      if (frame > startFrame + duration) {
        if (!userManuallyNavigated.current) {
          list.current.style.setProperty('--progress', '0');
          const index = (activeIndexRef.current + 1) % items.length;
          activeIndexRef.current = index;
          setActiveIndex(index);
        }

        return;
      }

      const progress = (frame - startFrame) / duration;

      if (progress < 0) return;
      list.current.style.setProperty('--progress', progress.toFixed(3));
    },
    [activeIndexRef]
  );

  const loopTimer = useRef<Timeout>();

  useEffect(() => {
    if (!isReady || !animation.current) return;

    const marker = markers.current[items[activeIndex].marker];
    if (!marker) return;

    // Add extra delay to loops
    if (activeIndex === 0 && isLooping.current) {
      animation.current.goToAndStop(marker.startFrame, true);

      loopTimer.current = setTimeout(() => {
        isLooping.current = false;
        animation.current?.play();
      }, 1000);
    } else {
      animation.current.goToAndStop(marker.startFrame, true);
      isLooping.current = false;
    }

    userManuallyNavigated.current = false;

    return () => clearTimeout(loopTimer.current);
  }, [isReady, activeIndex]);

  return (
    <Section
      className={styles.wrapper}
      wrapContent={false}
    >
      <Content>
        <h2>How it works</h2>
      </Content>
      <Line className={styles.line} />
      <Content className={styles.contentWrapper}>
        <div className={styles.listWrapper}>
          <ul
            ref={list}
            className={styles.list}
          >
            {items.map((item, i) => {
              // Use ref to avoid flash when double clicking
              // before React has re-rendered the component
              const isOpen = i === activeIndexRef.current;
              const buttonId = `${id}-button-${i}`;
              const contentId = `${id}-content-${i}`;

              return (
                <li
                  key={i}
                  className={classNames(styles.item, {
                    [styles.active]: isOpen
                  })}
                >
                  <button
                    id={buttonId}
                    className={styles.title}
                    aria-expanded={isOpen}
                    aria-controls={contentId}
                    onClick={() => {
                      if (activeIndexRef.current === i) return;

                      clearTimeout(loopTimer.current);
                      activeIndexRef.current = i;
                      userManuallyNavigated.current = true;
                      list.current?.style.setProperty('--progress', '0');
                      setActiveIndex(i);
                    }}
                  >
                    <div className={styles.number}>
                      <Progress className={styles.progress} />
                    </div>
                    {item.title}
                  </button>
                  <div
                    id={contentId}
                    className={styles.description}
                    aria-hidden={!isOpen}
                    aria-labelledby={buttonId}
                  >
                    <div className={styles.itemText}>
                      <MemoizedBodyText>{item.text}</MemoizedBodyText>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <figure
            className={styles.figure}
            aria-hidden="true"
          >
            <LottieAnimation
              ref={animation}
              // src={animationData}
              src="/animations/renters/how-it-works.lottie"
              poster={<Poster />}
              autoplay={false}
              delay={200}
              fonts={forceLoadFonts}
              onReady={handleReady}
              onFrame={handleFrame}
            />
          </figure>
        </div>
      </Content>
    </Section>
  );
}

const listEase = BezierEasing(0.04, 0.62, 0.23, 0.98);

type AnimationMarkers = {
  [name: string]: {
    startFrame: number;
    duration: number;
  };
};

const items: ListItem[] = [
  {
    title: 'Apply with your Property Manager',
    text: 'You’ll receive an application link in your approval email.',
    marker: 'APPROVAL_EMAIL'
  },
  {
    title: '60 second application',
    text: 'Add your details to get the best move-in options.',
    marker: 'APPLICATION'
  },
  {
    title: 'Choose deposit insurance or cash',
    text: 'Choose what’s best for you.',
    marker: 'DEPOSIT_OPTIONS'
  },
  {
    title: 'Make your payment',
    text: 'Pay for your Rhino policy by using various payment methods such as Credit, Apple Pay, and ACH.',
    marker: 'PAYMENT'
  },
  {
    title: 'We notify your landlord and you move in',
    text: 'We let them know, so you don’t have to.',
    marker: 'SUBMIT'
  }
];

const forceLoadFonts: AnimationFont[] = [
  'body-500',
  'body-600',
  'headline-500',
  'headline-600',
  'headline-700'
];

type HowItWorksProps = {
  startIndex?: number;
};

type ListItem = {
  title: string;
  text: React.ReactNode;
  marker: string;
  duration?: number;
};
