import React, { useState, useRef, useId } from 'react';
import styles from './Navigation.module.scss';
import Button from 'www/components/Button';
import Icon from 'www/components/Icon';
import Arrow from './arrow.svg';
import classNames from 'classnames';

export function NavItem({
  id,
  href,
  text,
  className,
  children,
  icon,
  isBold,
  isButton,
  as = 'li'
}: NavItemProps) {
  const Component = as;
  return (
    <Component className={classNames(styles.item, className)}>
      {children}
      {!isButton ? (
        <a
          id={id}
          href={href}
          className={classNames(styles.link, {
            [styles.bold]: isBold
          })}
        >
          <Icon
            name={icon}
            className={styles.icon}
          />
          <span className={styles.label}>{text}</span>
        </a>
      ) : (
        <Button
          id={id}
          href={href}
          className={styles.button}
          size="medium"
        >
          {text}
        </Button>
      )}
    </Component>
  );
}

export function DropdownNavItem({
  href,
  text,
  children,
  className,
  icon,
  isMobile,
  isMobileSubnavActive,
  mobileButtonDescription,
  onMobileButtonClick
}: DropdownNavItemProps) {
  const item = useRef<HTMLLIElement>(null);
  const subnavId = useId();
  const hasShownDropdown = useRef(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const forceShowDropdownTimeout = useRef<Timeout>();

  if (!hasShownDropdown.current && !isMobile && (isHovered || isFocused)) {
    hasShownDropdown.current = true;
  }

  const eventHandlers: React.LiHTMLAttributes<HTMLLIElement> = isMobile
    ? {}
    : {
        onMouseEnter: () => {
          clearTimeout(forceShowDropdownTimeout.current);

          const activeEl = document.activeElement;

          if (activeEl !== document.body && activeEl instanceof HTMLElement) {
            const prevFocusedItem = activeEl.closest(`.${styles.hasDropdown}`);

            if (prevFocusedItem && prevFocusedItem !== item.current) {
              activeEl.blur();
            }
          }

          setIsHovered(true);

          // Ensure dropdown doesn't hide with fast mouse movements.
          // We add the className directly to avoid a re-render
          // while the dropdown animation is still running
          item.current?.classList.add(styles.forceShowDropdown);

          forceShowDropdownTimeout.current = setTimeout(() => {
            item.current?.classList.remove(styles.forceShowDropdown);
          }, 150);
        },
        onMouseLeave: () => setIsHovered(false),
        onFocus: () => setIsFocused(true),
        onBlur: e => {
          const nextEl = e.relatedTarget as Node;

          if (!nextEl || !item.current?.contains(nextEl)) {
            setIsFocused(false);
          }
        }
      };

  return (
    <li
      ref={item}
      className={classNames(styles.item, styles.hasDropdown, className, {
        [styles.hasShownDropdown]: hasShownDropdown.current
      })}
      {...eventHandlers}
    >
      {!isMobile ? (
        <a
          href={href}
          className={styles.link}
          aria-haspopup="menu"
          aria-expanded={isHovered || isFocused}
          aria-controls={subnavId}
        >
          <Icon
            name={icon}
            className={styles.icon}
          />
          <span className={styles.label}>{text}</span>
          <Arrow className={styles.arrow} />
        </a>
      ) : (
        <button
          className={classNames(styles.subnavButton, {
            [styles.noDescription]: !mobileButtonDescription
          })}
          aria-haspopup="menu"
          aria-expanded={isMobileSubnavActive}
          aria-controls={subnavId}
          onClick={e => {
            if (e.target instanceof HTMLElement) e.target.blur();
            onMobileButtonClick();
          }}
        >
          <Icon
            name={icon}
            className={styles.icon}
          />
          <span className={styles.subnavButtonText}>
            <strong className={styles.subnavButtonTitle}>{text}</strong>
            {mobileButtonDescription && (
              <span className={styles.subnavButtonDescription}>
                {mobileButtonDescription}
              </span>
            )}
          </span>
          <Arrow className={styles.arrow} />
        </button>
      )}
      <div
        id={subnavId}
        className={classNames(styles.subnavWrapper, {
          [styles.subnavActive]: isMobileSubnavActive
        })}
      >
        {children}
      </div>
    </li>
  );
}

type NavItemSharedProps = {
  href: string;
  text: React.ReactNode;
  className?: string;
  icon?: string;
};

type NavItemProps = NavItemSharedProps & {
  id?: string;
  icon?: string;
  isBold?: boolean;
  isButton?: boolean;
  children?: React.ReactNode;
  as?: keyof JSX.IntrinsicElements;
};

type DropdownNavItemProps = NavItemSharedProps & {
  isMobile: boolean;
  isMobileSubnavActive: boolean;
  children: React.ReactNode;
  mobileButtonDescription?: string;
  onMobileButtonClick: () => void;
};
