import React, {
  type ReactNode,
  type FunctionComponent,
  type HTMLAttributes
} from 'react';
import styles from './Heading.module.scss';
import classNames from 'classnames';

export const Heading: FunctionComponent<HeadingComponentProps> = ({
  align,
  className,
  tag: Tag,
  tagline,
  taglineId,
  taglineSeparator = '-',
  children,
  ...rest
}) => {
  return !children ? null : (
    <Tag
      className={
        classNames(className, {
          'has-tagline': !!tagline,
          [styles.center]: align === 'center'
        }) || undefined
      }
      {...rest}
    >
      {tagline && (
        <>
          <span
            id={taglineId}
            className={classNames('tagline', styles.tagline)}
          >
            {tagline}
            {typeof tagline === 'string' && taglineSeparator && (
              <span className="sr">{taglineSeparator}</span>
            )}
          </span>
        </>
      )}
      {children}
    </Tag>
  );
};

export const H1 = (p: HeadingProps) => (
  <Heading
    {...p}
    tag="h1"
  />
);
export const H2 = (p: HeadingProps) => (
  <Heading
    {...p}
    tag="h2"
  />
);
export const H3 = (p: HeadingProps) => (
  <Heading
    {...p}
    tag="h3"
  />
);
export const H4 = (p: HeadingProps) => (
  <Heading
    {...p}
    tag="h4"
  />
);
export const H5 = (p: HeadingProps) => (
  <Heading
    {...p}
    tag="h5"
  />
);
export const H6 = (p: HeadingProps) => (
  <Heading
    {...p}
    tag="h6"
  />
);

export type HeadingProps = {
  align?: 'left' | 'center';
  tagline?: string | ReactNode;
  taglineId?: string;
  taglineSeparator?: string | null;
} & HTMLAttributes<HTMLInputElement>;

export type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type HeadingComponentProps = HeadingProps & {
  tag: HeadingTag;
};
