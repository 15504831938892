import React, { type ComponentProps, type CSSProperties } from 'react';
import styles from './Spinner.module.scss';
import classNames from 'classnames';
import { isNumber } from 'www/utils/helpers';

const baseSize = parseInt(styles.baseSize, 10);
const baseThickness = parseInt(styles.baseThickness, 10);

export default function Spinner({
  size,
  className,
  svgClassName,
  color,
  thickness = baseThickness,
  isVisible = true
}: SpinnerProps) {
  const wrapperStyle: CSSProperties = { color };

  if (isNumber(size) && size !== baseSize) {
    wrapperStyle['--spinner-size'] = `${size}px`;
  }

  if (isNumber(thickness) && thickness !== baseThickness) {
    wrapperStyle['--spinner-stroke-width'] = thickness;
  }

  size ??= baseSize;

  return !isVisible ? null : (
    <span
      className={classNames(styles.wrapper, className)}
      style={wrapperStyle}
      role="progressbar"
    >
      <svg
        className={classNames(styles.spinner, svgClassName)}
        viewBox={`0 0 ${size} ${size}`}
      >
        <circle
          className={styles.circle}
          cx={size / 2}
          cy={size / 2}
          r={(size - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </span>
  );
}

type SpinnerProps = ComponentProps<'div'> & {
  size?: number;
  svgClassName?: string;
  thickness?: number;
  isVisible?: boolean;
};
