import React from 'react';
import styles from './HeroAnimation.module.scss';
import BrowserButtons from 'www/components/BrowserButtons';
import {
  LottieAnimation,
  type AnimationFont
} from 'www/components/LottieAnimation';
import Poster from './poster.svg';
// import animation from './data.json';
import animation from 'www/animations/home/hero.lottie';

export default function HeroAnimation() {
  return (
    <div className={styles.wrapper}>
      <BrowserButtons className={styles.browserButtons} />
      <LottieAnimation
        poster={<Poster />}
        intermission={2000}
        fonts={forceLoadFonts}
        delay={200}
        src={animation}
      />
    </div>
  );
}

const forceLoadFonts: AnimationFont[] = [
  'body-500',
  'body-600',
  'headline-500',
  'headline-600',
  'headline-700'
];
