import { useState, useEffect } from 'react';

export default function useIsResizing(minRestTime: number = 100) {
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    let hasStartedResizing = false;
    let resizeTimer: Timeout;

    const resizeHandler = () => {
      if (!hasStartedResizing) {
        hasStartedResizing = true;
        setIsResizing(true);
      }

      clearTimeout(resizeTimer);

      resizeTimer = setTimeout(() => {
        hasStartedResizing = false;
        setIsResizing(false);
      }, minRestTime);
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      clearTimeout(resizeTimer);
      window.removeEventListener('resize', resizeHandler);
    };
  }, [minRestTime]);

  return isResizing;
}
