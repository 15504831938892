import React from 'react';
import styles from './DemoForm.module.scss';
import BlobBackground from './blob.svg';
import { Section } from 'www/components/layout';
import TextInput from 'www/components/TextInput';
import SelectInput from 'www/components/SelectInput';
import Button from 'www/components/Button';
import Alert from 'www/components/Alert';
import useDemoForm from 'www/hooks/useDemoForm';
import classNames from 'classnames';
import env from 'utils/env';

import imgWebp from 'www/images/demo/apartment.webp';
import imgJpg from 'www/images/demo/apartment.jpg';

export default function DemoForm() {
  const { state, onChange, submit, unitsList } = useDemoForm();

  return (
    <Section className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <h1>
            Ready to <strong>streamline your move-ins?</strong>
          </h1>
          <p>Say goodbye to hassle and hello to satisfied renters.</p>

          <Button
            className={styles.demoButton}
            id="demo-request-a-demo-click-out-link"
            href={env('RHINO_REQUEST_DEMO_URL')}
            target="_blank"
          >
            Request a demo
          </Button>

          {/* <div
            className={classNames(styles.formWrapper, {
              [styles.hasSucceeded]: state.hasSucceeded
            })}
          >
            {state.hasSucceeded && <SuccessMessage />}

            <form
              className={styles.form}
              aria-label="Request a demo form"
              onSubmit={submit}
              noValidate
            >
              {state.errors.general && (
                <Alert type="error">{state.errors.general}</Alert>
              )}

              <TextInput
                value={state.email}
                type="email"
                name="email"
                label="Company email"
                placeholder="Enter your email"
                error={state.errors.email}
                canShowError={state.canShowErrors}
                required
                onChange={onChange}
              />

              <SelectInput
                value={state.units}
                name="units"
                label="Units under management"
                placeholder="Select one"
                error={state.errors.units}
                canShowError={state.canShowErrors}
                required
                onChange={onChange}
              >
                {unitsList.map((value, index) => (
                  <option
                    key={index}
                    value={value}
                  >
                    {value}
                  </option>
                ))}
              </SelectInput>

              <Button
                id="request-a-demo-form-submit-button"
                type="submit"
                value={state.units}
                className={styles.submit}
                isLoading={state.isLoading || state.hasSucceeded}
              >
                Request a demo
              </Button>
            </form>
          </div> */}
        </div>

        <figure className={styles.figure}>
          <blockquote className={styles.quote}>
            <p>
              Rhino contributed to [only] one issue for us; we didn’t have
              enough inventory to lease out.
            </p>
            <cite>Trey Cummings, Omega Realty Group</cite>
          </blockquote>
          <picture>
            <source
              type="image/webp"
              srcSet={imgWebp}
            />
            <img
              src={imgJpg}
              className="object-cover"
              alt=""
            />
          </picture>
        </figure>
      </div>
      <div className={styles.blobWrapper}>
        <BlobBackground className={styles.blob} />
      </div>
    </Section>
  );
}

function SuccessMessage() {
  return (
    <div className={styles.success}>
      <svg
        className={styles.checkmark}
        viewBox="0 0 52 52"
      >
        <circle
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
      <div className={styles.successText}>
        <strong>Thank you, your submission has been received</strong>
        <p>We&apos;ll be in touch with you soon!</p>
      </div>
    </div>
  );
}
