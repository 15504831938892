import React, { useId, type InputHTMLAttributes } from 'react';
import Icon from 'www/components/Icon';
import classNames from 'classnames';

export default function TextInput({
  type = 'text',
  className,
  label,
  error,
  canShowError,
  ...restProps
}: TextInputProps) {
  const id = useId();
  const errorId = useId();

  return (
    <div
      className={classNames('field', className, {
        'has-error': error && canShowError
      })}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <input
        id={id}
        type={type}
        aria-invalid={!!error && canShowError}
        aria-errormessage={error && canShowError ? errorId : undefined}
        {...restProps}
      />
      {error && canShowError && (
        <div
          id={errorId}
          className="field-error"
          role="alert"
        >
          <Icon name="alert-circle" /> {error}
        </div>
      )}
    </div>
  );
}

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  error?: string;
  canShowError?: boolean;
};
