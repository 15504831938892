import React from 'react';
import styles from './StarRating.module.scss';
import Icon from 'www/components/Icon';
import classNames from 'classnames';

export default function StarRating({ rating, className }: StarRatingProps) {
  return (
    <div
      className={classNames(styles.wrapper, className)}
      style={{
        '--rating': rating
      }}
    >
      <Stars className={styles.active} />
      <Stars className={styles.inactive} />
    </div>
  );
}

function Stars({ className }: StarsWrapperProps) {
  return (
    <div className={classNames(styles.stars, className)}>
      {Array.from({ length: 5 }).map((_, index) => (
        <span key={index}>
          <Icon name="star" />
        </span>
      ))}
    </div>
  );
}

type StarRatingProps = {
  className?: string;
  rating?: number | string;
};

type StarsWrapperProps = {
  className: string;
};
