import React from 'react';
import styles from './Stats.module.scss';
import { StatsSection, Stat } from 'www/components/StatsSection';

export default function Stats() {
  return (
    <StatsSection
      className={styles.wrapper}
      headline={
        <>
          <strong>33 of the top 100</strong> landlords and property managers use
          Rhino
        </>
      }
      tagline="Why choose Rhino"
      sectionTopText="Rhino is bridging the gap between renters, landlords, and insurance carriers –  Creating a streamlined, cost-effective, and time-saving solution that fosters trust and efficiency in the industry."
    >
      <Stat
        value="6m"
        bottomLabel="home partner network"
      />
      <Stat
        value="$1b"
        bottomLabel="cash saved for renters"
      />
      <Stat
        value=">40%"
        bottomLabel="increase in leasing velocity"
      />
    </StatsSection>
  );
}
