import React, { useState, useId } from 'react';
import styles from './TeamUseCases.module.scss';
import { Section, SectionTop } from 'www/components/layout';
import { Icon, IconText } from 'www/components/Icon';
import BodyText from 'www/components/BodyText';
import classNames from 'classnames';
import Arrow from './arrow.svg';

export default function TeamUseCases() {
  return (
    <Section className={styles.wrapper}>
      <SectionTop
        headline="Elevating teams across every stage of the rental journey"
        isCentered
      />
      <TeamTabs normalizeHeight />
    </Section>
  );
}

function TeamTabs({ normalizeHeight }: TeamTabsProps) {
  const id = useId();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={styles.content}>
      <div className={styles.mobileTabsWrapper}>
        <Icon
          name={teams[activeIndex].icon}
          className={styles.mobileTabIcon}
        />
        <select
          className={styles.mobileTabSelect}
          value={activeIndex}
          onChange={e => setActiveIndex(Number(e.target.value))}
        >
          {teams.map(({ label }, i) => (
            <option
              key={i}
              value={i}
            >
              {label}
            </option>
          ))}
        </select>
        <Arrow
          className={styles.mobileTabSelectArrow}
          aria-hidden="true"
        />
      </div>
      <ul className={styles.tabs}>
        {teams.map(({ label, icon }, i) => {
          const isActive = i === activeIndex;

          return (
            <li
              key={i}
              className="dashed-line"
            >
              <button
                role="tab"
                id={`${id}-tab-button-${i}`}
                className={classNames(styles.tab, {
                  [styles.active]: isActive
                })}
                onClick={() => setActiveIndex(i)}
                aria-controls={`${id}-tab-${i}`}
                aria-selected={isActive}
              >
                <IconText icon={icon}>{label}</IconText>
              </button>
            </li>
          );
        })}
      </ul>
      <div
        className={classNames(styles.details, {
          [styles.normalizeHeight]: normalizeHeight
        })}
      >
        {teams.map(({ icon, title, text }, i) => {
          const isActive = i === activeIndex;

          return (
            <div
              key={i}
              id={`${id}-tab-${i}`}
              className={classNames(styles.detailsText, {
                [styles.active]: isActive
              })}
              role="tabpanel"
              aria-labelledby={title ? `${id}-title` : `${id}-tab-button-${i}`}
              aria-hidden={!isActive}
            >
              <Icon
                name={icon}
                className={styles.detailsIcon}
              />
              {title && <h3 id={`${id}-title`}>{title}</h3>}
              <BodyText>{text}</BodyText>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const teams = [
  {
    label: 'Property & site managers',
    icon: 'landlords',
    title: 'Introducing Rhino for property and site managers',
    text: 'Our deposit insurance offers more coverage than traditional cash security deposits, freeing up your time from collections. With Rhino, properties lease up to 50% faster, expanding your renter pool without added risk. Benefit from subsidized marketing support for enhanced leasing velocity. Our fast 4-day claims process and user-friendly platform streamline reimbursement. Plus, enjoy dedicated account management and support teams for peace of mind. Join Rhino for more coverage, faster leasing, and exceptional support.'
  },
  {
    label: 'Owners & executive sponsors',
    icon: 'computer-chart',
    title: 'Introducing Rhino for owners and executive sponsors',
    text: 'Experience unparalleled deposit insurance with over 80% adoption, slashing bad debt. Rhino-powered properties lease up to 50% faster, cutting vacancies and marketing expenses. Partner with us to project a cutting-edge, renter-centric image and enjoy swift, 4-day claims processing. With Rhino, unlock enhanced coverage, expedited leasing, and a distinct competitive advantage.'
  },
  {
    label: 'Finance & accounting teams',
    icon: 'cash',
    title: 'Introducing Rhino for finance teams',
    text: 'Elevate your finances with our deposit insurance, providing superior coverage and boosting Net Operating Income (NOI) while reducing bad debt. Simplify leasing with Rhino, cutting upfront costs and vacancy days. Benefit from marketing support to drive leasing velocity and maximize income. Our swift 4-day claims processing ensures quick reimbursement for damages or lost rent, with easy claims submission. Join Rhino for enhanced coverage, streamlined leasing, and financial resilience.'
  },
  {
    label: 'Leasing agents',
    icon: 'briefcase',
    title: 'Introducing Rhino for leasing agents',
    text: 'Simplify your workflow with Rhino, merging Security Deposit Insurance and cash collections seamlessly to save you hours each week. Our integrated solution reduces manual tasks, ensuring greater efficiency. Properties with Rhino lease up to 50% faster, helping agents lease more units swiftly and boost commissions. Enjoy personalized training from dedicated account managers, equipping you with the tools to promote Rhino during lease signings. Forgetfulness is no problem with our comprehensive marketing materials for distribution to potential renters. Experience Rhino for increased coverage, faster leasing, and leasing success support.'
  }
];

type TeamTabsProps = {
  normalizeHeight?: boolean;
};
