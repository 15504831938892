import React, { useId, useState, useRef, useEffect, useMemo } from 'react';
import styles from './Tabs.module.scss';
import useIsMounted from 'www/hooks/useIsMounted';
import useSize from 'www/hooks/useSize';
import { round } from 'www/utils/helpers';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';

export default function Tabs({ tabs, className, contentClassName }: TabsProps) {
  const id = useId();
  const [activeTab, setActiveTab] = useState(0);
  const [hidingTab, setHidingTab] = useState<Nullable<number>>(null);
  const tabsWrapper = useRef<HTMLDivElement>(null);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (hidingTab === null) return;
    if (hidingTab === activeTab) {
      setHidingTab(null);
      return;
    }

    const timer = setTimeout(() => {
      if (!isMounted()) return;
      setHidingTab(null);
    }, 300);

    return () => clearTimeout(timer);
  }, [hidingTab, activeTab, isMounted]);

  const wrapper = useRef<HTMLDivElement>(null);
  const wrapperRect = useSize(wrapper);
  const buttons = useRef<HTMLDivElement>(null);
  const buttonsRect = useSize(buttons);

  const isScrollable = useMemo(() => {
    return !wrapperRect || !buttonsRect
      ? false
      : buttonsRect.width > wrapperRect.width;
  }, [wrapperRect, buttonsRect]);

  // Show/hide white shadows when tabs are scrolled on mobile
  const { ref: leftSentinel, entry: leftSentinelEntry } = useInView({
    threshold: intersectionThresholds
  });

  const { ref: rightSentinel, entry: rightSentinelEntry } = useInView({
    threshold: intersectionThresholds
  });

  return !tabs?.length ? null : (
    <div
      className={classNames(styles.wrapper, className)}
      ref={wrapper}
      role="tablist"
    >
      <div
        className={classNames(styles.buttonsWrapper, {
          [styles.scrollable]: isScrollable
        })}
        style={{
          '--left-shadow-opacity': !isScrollable
            ? undefined
            : getShadowOpacity(leftSentinelEntry, 'left'),
          '--right-shadow-opacity': !isScrollable
            ? undefined
            : getShadowOpacity(rightSentinelEntry, 'right')
        }}
      >
        <div className={styles.buttonsScroller}>
          <div
            className={styles.buttons}
            ref={buttons}
          >
            <div
              ref={leftSentinel}
              className={classNames(styles.sentinel, styles.left)}
            />
            <div
              ref={rightSentinel}
              className={classNames(styles.sentinel, styles.right)}
            />

            {tabs.map((tab, i) => {
              const isActive = i === activeTab;

              return (
                <button
                  key={i}
                  role="tab"
                  id={`${id}-tab-button-${i}`}
                  className={classNames(styles.button, {
                    'button-active': isActive
                  })}
                  aria-controls={`${id}-tab-${i}`}
                  aria-selected={isActive}
                  tabIndex={isActive ? 0 : -1}
                  onClick={() => {
                    setHidingTab(i === activeTab ? null : activeTab);
                    setActiveTab(i);
                  }}
                  onKeyDown={e => {
                    if (e.key !== 'ArrowRight' && e.key !== 'ArrowLeft') return;

                    e.preventDefault();

                    const nextTab =
                      e.key === 'ArrowRight'
                        ? (i + 1) % tabs.length
                        : (i - 1 + tabs.length) % tabs.length;

                    const nextButton = document.getElementById(
                      `${id}-tab-button-${nextTab}`
                    );

                    setHidingTab(i);
                    setActiveTab(nextTab);

                    nextButton?.focus();
                  }}
                >
                  {tab.text}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.content, contentClassName)}
        ref={tabsWrapper}
      >
        {tabs.map((tab, i) => {
          const isActive = i === activeTab;

          return (
            <div
              key={i}
              id={`${id}-tab-${i}`}
              className={classNames(styles.tab, tab.className, {
                'tab-active': isActive,
                'tab-hiding': i === hidingTab
              })}
              role="tabpanel"
              aria-labelledby={`${id}-tab-button-${i}`}
              aria-hidden={!isActive}
            >
              {tab.content}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const intersectionThresholds = Array.from({ length: 20 }, (_, i) => i / 19);

function getShadowOpacity(
  entry: IntersectionObserverEntry | undefined,
  position: 'left' | 'right'
) {
  if (!entry) return 0;

  const { boundingClientRect: rect, rootBounds } = entry;

  if (rect.width === 0 || !rootBounds) return 0;

  if (position === 'left') {
    if (rect.left >= 0) return 0;
    if (rect.left <= -rect.width) return 1;

    return Math.min(round(Math.abs(rect.left / rect.width), 2), 1);
  } else {
    if (rect.left >= rootBounds.right) return 1;
    if (rect.right <= rootBounds.right) return 0;

    const ratio = 1 - Math.abs((rootBounds.right - rect.left) / rect.width);
    return Math.min(round(ratio, 2), 1);
  }
}

type Tab = {
  text: string;
  content: React.ReactNode;
  className?: string;
};

type TabsProps = {
  tabs: Tab[];
  className?: string;
  contentClassName?: string;
};
