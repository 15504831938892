import React from 'react';
import styles from './PlatformOverview.module.scss';
import { Section } from 'www/components/layout';
import PlatformLabel from 'www/components/PlatformLabel';
import { List, ListItem } from 'www/components/List';
import { ArrowButton } from 'www/components/Button';
import {
  LottieAnimation,
  type AnimationFont
} from 'www/components/LottieAnimation';
import ApprovalFlowPoster from './approval-flow-poster.svg';
import animation from 'www/animations/home/approval-flow.lottie';
import classNames from 'classnames';

import renterImgJpg from 'www/images/home/couple-painting.jpg';
import renterImgWebp from 'www/images/home/couple-painting.webp';

export default function PlatformOverview() {
  return (
    <Section className={styles.wrapper}>
      <Box
        type="landlords"
        headline="Optimize, automate, and streamline the renting process"
        figure={
          <LottieAnimation
            poster={<ApprovalFlowPoster />}
            delay={500}
            intermission={2000}
            fonts={forceLoadFonts}
            src={animation}
          />
        }
      >
        <p>
          Collect move in fees, renters insurance and all other requirements in
          one easy transaction. Get the coverage you need, without losing
          renters.
        </p>

        <List className={styles.list}>
          <ListItem icon="unified">One portal for the deposit journey</ListItem>
          <ListItem icon="filter">Customized for your business needs</ListItem>
          <ListItem icon="clock">Save time and lease faster</ListItem>
          <ListItem icon="click">Never leave your PMS</ListItem>
        </List>

        <ArrowButton href="/partners">View our solutions</ArrowButton>
      </Box>

      <Box
        type="renters"
        headline="Take control of all your&nbsp;options"
        figure={
          <picture>
            <source
              type="image/webp"
              srcSet={renterImgWebp}
            />
            <img
              src={renterImgJpg}
              className="object-cover"
              alt=""
              loading="lazy"
            />
          </picture>
        }
      >
        <p>
          Rhino takes the stress (and cost) out of moving. See what works
          with your journey.
        </p>

        <List className={styles.list}>
          <ListItem icon="lock">Security deposit insurance</ListItem>
          <ListItem icon="cash">Digital cash deposits</ListItem>
          <ListItem icon="insurance">Renters Insurance</ListItem>
          <ListItem icon="report-money">Lease guarantee</ListItem>
        </List>

        <ArrowButton href="/renters">View your options</ArrowButton>
      </Box>
    </Section>
  );
}

function Box({ type, headline, children, figure }: BoxProps) {
  return (
    <div
      className={classNames(
        styles.box,
        type === 'landlords' ? styles.landlords : styles.renters
      )}
    >
      <div className={styles.text}>
        <PlatformLabel type={type} />
        <h2 className="small">{headline}</h2>
        {children}
      </div>
      <figure
        className={styles.figure}
        role="presentation"
      >
        {figure}
      </figure>
    </div>
  );
}

type BoxProps = {
  type: 'landlords' | 'renters';
  headline?: string | React.ReactNode;
  children?: React.ReactNode;
  figure?: string | React.ReactNode;
};

const forceLoadFonts: AnimationFont[] = [
  'body-500',
  'body-600',
  'headline-500',
  'headline-600',
  'headline-700'
];
