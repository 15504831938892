import React, { forwardRef, type ComponentPropsWithRef } from 'react';
import { Content } from 'www/components/layout';
import { ArrowButton } from 'www/components/Button';
import {
  Heading,
  type HeadingProps,
  type HeadingTag
} from 'www/components/Heading';
import BodyText from 'www/components/BodyText';
import PlatformLabel from 'www/components/PlatformLabel';
import classNames from 'classnames';

export default forwardRef<HTMLElement, SectionProps>(function Section(
  {
    wrapContent = true,
    className,
    contentClassName,
    children,
    isCentered,
    maxWidth,
    style,
    ...restProps
  },
  ref
) {
  const wrapperStyle =
    !style && !maxWidth
      ? undefined
      : {
          ...(style ?? {}),
          '--content-max-width': maxWidth ? `${maxWidth}px` : undefined
        };

  return (
    <section
      ref={ref}
      style={wrapperStyle}
      className={classNames('section', className, {
        'text-center': isCentered
      })}
      {...restProps}
    >
      {!wrapContent ? (
        children
      ) : (
        <Content className={contentClassName}>{children}</Content>
      )}
    </section>
  );
});

export function SectionTop({
  className,
  headlineTag = 'h2',
  headline,
  platformLabel,
  platformLabelClassName,
  children,
  align,
  tagline,
  taglineId,
  taglineSeparator,
  maxWidth,
  isCentered,
  isCenteredDesktop,
  buttonText,
  buttonLink
}: SectionTopProps) {
  return !headline && !children ? null : (
    <div
      style={{ '--max-width': maxWidth ? `${maxWidth}px` : undefined }}
      className={classNames('section-top', className, {
        'text-center': isCentered,
        'text-center-desktop': isCenteredDesktop
      })}
    >
      {platformLabel && (
        <PlatformLabel
          type={platformLabel}
          className={platformLabelClassName}
        />
      )}
      <Heading
        tag={headlineTag}
        align={align}
        tagline={tagline}
        taglineId={taglineId}
        taglineSeparator={taglineSeparator}
      >
        {headline}
      </Heading>
      <BodyText>{children}</BodyText>
      {buttonText && <ArrowButton href={buttonLink}>{buttonText}</ArrowButton>}
    </div>
  );
}

type SectionTopProps = HeadingProps & {
  className?: string;
  headlineTag?: HeadingTag;
  headline?: string | React.ReactNode;
  children?: string | React.ReactNode;
  maxWidth?: number;
  isCentered?: boolean;
  isCenteredDesktop?: boolean;
  buttonText?: string | React.ReactNode;
  buttonLink?: string;
  platformLabel?: 'renters' | 'landlords';
  platformLabelClassName?: string;
};

type SectionProps = {
  wrapContent?: boolean;
  contentClassName?: string;
  contentTag?: keyof JSX.IntrinsicElements;
  isCentered?: boolean;
  maxWidth?: number;
} & ComponentPropsWithRef<'section'>;
