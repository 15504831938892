import React, { useId } from 'react';
import styles from './Hero.module.scss';
import Animation from './PartnersHeroAnimation';
import { Section, SectionTop, Content } from 'www/components/layout';
import PlatformLabel from 'www/components/PlatformLabel';
import Button from 'www/components/Button';
import Icon from 'www/components/Icon';
import BodyText from 'www/components/BodyText';
import useDemoForm from 'www/hooks/useDemoForm';
import classNames from 'classnames';
import env from 'utils/env';

export default function Hero() {
  const emailId = useId();
  const emailErrorId = useId();
  const unitsId = useId();
  const unitsErrorId = useId();
  const { state, onChange, submit, unitsList } = useDemoForm();
  const hasEmailError = state.canShowErrors && state.errors.email;
  const hasUnitsError = state.canShowErrors && state.errors.units;

  return (
    <Section
      className={classNames(styles.wrapper, 'hero-bg')}
      wrapContent={false}
    >
      <div className={styles.contentWrapper}>
        <Content>
          <PlatformLabel type="landlords" />
          <SectionTop
            headline="Modernize move-in and move-out payments"
            headlineTag="h1"
            className={styles.text}
            isCentered
          >
            <p>
              Streamline move-ins effortlessly with our automated digital
              payment system, seamlessly integrated with your PMS. Say goodbye
              to hassle and hello to satisfied renters.
            </p>
            <Button
              id="partners-request-a-demo-click-out-link"
              href={env('RHINO_REQUEST_DEMO_URL')}
              target="_blank"
            >
              Request a demo
            </Button>
          </SectionTop>

          {/* <div
            className={classNames(styles.formWrapper, {
              [styles.messageVisible]:
                state.hasSucceeded || state.errors.general
            })}
          >
            <form
              className={styles.form}
              aria-label="Request a demo form"
              onSubmit={submit}
              noValidate
            >
              <div
                className={classNames(styles.inputWrapper, {
                  [styles.hasError]: hasEmailError
                })}
              >
                <label htmlFor={emailId}>Company email</label>
                <input
                  id={emailId}
                  className={styles.email}
                  value={state.email}
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  aria-invalid={hasEmailError ? 'true' : 'false'}
                  aria-errormessage={hasEmailError ? emailErrorId : undefined}
                  required
                  onChange={onChange}
                />
                {hasEmailError && (
                  <div
                    id={emailErrorId}
                    className={styles.error}
                  >
                    <Icon name="alert-circle" />
                    {state.errors.email}
                  </div>
                )}
              </div>
              <div
                className={classNames(styles.inputWrapper, {
                  [styles.hasError]: hasUnitsError
                })}
              >
                <label htmlFor={unitsId}>Units under management</label>
                <select
                  id={unitsId}
                  name="units"
                  className={styles.units}
                  value={state.units}
                  aria-invalid={hasUnitsError ? 'true' : 'false'}
                  aria-errormessage={hasUnitsError ? unitsErrorId : undefined}
                  required
                  onChange={onChange}
                >
                  <option
                    value=""
                    disabled
                  >
                    Select one
                  </option>
                  {unitsList.map((value, index) => (
                    <option
                      key={index}
                      value={value}
                    >
                      {value}
                    </option>
                  ))}
                </select>
                <Icon
                  name="chevron"
                  className={styles.arrow}
                />
                {hasUnitsError && (
                  <div
                    id={unitsErrorId}
                    className={styles.error}
                  >
                    <Icon name="alert-circle" />
                    {state.errors.units}
                  </div>
                )}
              </div>
              <Button
                type="submit"
                className={styles.button}
                size="medium"
                isLoading={state.isLoading || state.hasSucceeded}
              >
                Request a demo
              </Button>
            </form>

            {state.hasSucceeded && (
              <div className={classNames(styles.message, styles.success)}>
                <div className={styles.messageText}>
                  <Icon name="circle-check" />
                  <div>
                    <p>
                      Thank you, your submission has been received. We&apos;ll
                      be in touch soon!
                    </p>
                  </div>
                </div>
              </div>
            )}
            {!state.hasSucceeded && state.errors.general && (
              <div className={classNames(styles.message, styles.fail)}>
                <div className={styles.messageText}>
                  <Icon name="alert-circle" />
                  <div>
                    <BodyText>{state.errors.general}</BodyText>
                  </div>
                </div>
              </div>
            )}
          </div> */}
          <Animation />
        </Content>
      </div>
    </Section>
  );
}
