import { useState, useEffect, useRef } from 'react';
import useVariableRef from './useVariableRef';
import {
  useInView,
  type IntersectionOptions
} from 'react-intersection-observer';

export default function useIsVisible({
  fallbackInView = true,
  trackPageVisibility = true,
  onChange,
  ...restOptions
}: VisibilityOptions = {}) {
  const [pageIsVisible, setPageIsVisible] = useState(true);
  const prevIsVisible = useRef(pageIsVisible);
  const onChangeRef = useVariableRef(onChange);

  useEffect(() => {
    if (!trackPageVisibility) return;

    const setVisibility = () => {
      const isVisible = !document.hidden;

      if (isVisible === prevIsVisible.current) return;
      prevIsVisible.current = isVisible;

      onChangeRef.current?.(isVisible);
      setPageIsVisible(isVisible);
    };

    setVisibility();

    document.addEventListener('visibilitychange', setVisibility);

    return () => {
      document.removeEventListener('visibilitychange', setVisibility);
    };
  }, [trackPageVisibility, onChangeRef]);

  const { ref, inView } = useInView({
    fallbackInView,
    ...restOptions,
    onChange
  });

  const isVisible = !trackPageVisibility ? inView : inView && pageIsVisible;

  return { ref, isVisible };
}

type VisibilityOptions = IntersectionOptions & {
  trackPageVisibility?: boolean;
  onChange?: (isVisible: boolean) => void;
};
