import React, { useState, useEffect } from 'react';
import styles from './Navigation.module.scss';
import { NavItem, DropdownNavItem } from './NavItem';
import { Subnav, SubnavColumn, SubnavLink } from './Subnav';
import MenuIcon from './menu-icon.svg';
import { Icon, IconText } from 'www/components/Icon';
import Button from 'www/components/Button';
import useMatchMedia from 'www/hooks/useMatchMedia';
import useEffectAfterMount from 'www/hooks/useEffectAfterMount';
import useIsWindowResizing from 'www/hooks/useIsWindowResizing';
import Logo from 'www/images/logo.svg';
import { RemoveScroll } from 'react-remove-scroll';
import FocusLock from 'react-focus-lock';
import classNames from 'classnames';
import gsap from 'gsap';

export default function Navigation() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isMobile = useMatchMedia(`(max-width: ${styles.mobileBreakpoint})`);

  // Prevent flicker in dev before media query runs
  const [isInitializing, setIsInitializing] = useState(true);
  useEffect(() => setIsInitializing(false), []);

  const isResizing = useIsWindowResizing(500);
  const [activeMobileSubnav, setActiveMobileSubnav] =
    useState<Nullable<string>>(null);

  if (!isMobile && isMobileMenuOpen) setIsMobileMenuOpen(false);

  useEffectAfterMount(() => {
    if (isMobileMenuOpen) {
      window.dispatchEvent(new CustomEvent('rhino:menu-open'));
      return;
    }

    window.dispatchEvent(new CustomEvent('rhino:menu-close'));
    gsap.globalTimeline.play();

    const timer = setTimeout(() => {
      setActiveMobileSubnav(null);
    }, 200);

    return () => clearTimeout(timer);
  }, [isMobileMenuOpen]);

  const demoUrl = '/demo';

  return (
    <>
      <FocusLock
        className={classNames(styles.wrapper, {
          [styles.mobileMenuOpen]: isMobile && isMobileMenuOpen,
          [styles.resizing]: isResizing,
          [styles.initializing]: isInitializing
        })}
        disabled={!isMobile || !isMobileMenuOpen}
        autoFocus={false}
      >
        <RemoveScroll
          className={styles.scrollWrapper}
          enabled={isMobile && isMobileMenuOpen}
        >
          <nav
            className={classNames(styles.nav, {
              [styles.mobileSubnavOpen]: isMobile && activeMobileSubnav
            })}
            aria-label="Main"
            tabIndex={-1}
          >
            <div className={styles.mobileHeader}>
              <Logo
                className={styles.mobileLogo}
                aria-hidden="true"
              />
              <button
                className={styles.backButton}
                onClick={() => setActiveMobileSubnav(null)}
              >
                <Icon
                  name="chevron"
                  className={styles.backArrow}
                />
                Back
              </button>
              <button
                className={styles.closeButton}
                onClick={() => {
                  setIsMobileMenuOpen(false);
                }}
              >
                <span className="sr">Close menu</span>
              </button>
            </div>
            <div className={styles.menuWrapper}>
              <ul className={styles.menu}>
                <DropdownNavItem
                  text="Renters"
                  href="/renters"
                  icon="renters-stroke"
                  className={styles.renters}
                  isMobile={isMobile}
                  isMobileSubnavActive={activeMobileSubnav === 'renters'}
                  mobileButtonDescription="Rhino offers customized payment options to simplify the renting process."
                  onMobileButtonClick={() => setActiveMobileSubnav('renters')}
                >
                  <Subnav>
                    <SubnavColumn
                      title="Overview"
                      className={styles.rentersOverview}
                    >
                      <a
                        href="/renters"
                        className={styles.overview}
                      >
                        <IconText icon="renters">Renters</IconText>
                        <p>
                          Rhino offers customized payment options to simplify
                          the renting process.
                        </p>
                        <Button
                          tag="div"
                          theme="secondary"
                        >
                          {isMobile ? 'Renters overview' : 'Why choose Rhino'}
                        </Button>
                      </a>
                    </SubnavColumn>
                    <SubnavColumn title="Products">
                      <ul>
                        <li>
                          <SubnavLink
                            text="Security deposit insurance"
                            href="/products/security-deposit-insurance"
                            icon="lock"
                            description="We’re making moving in more affordable"
                          />
                        </li>
                        <li>
                          <SubnavLink
                            text="Digital cash deposits"
                            href="/products/cash"
                            icon="cash"
                            description="Security deposits for the digital age"
                          />
                        </li>
                        <li>
                          <SubnavLink
                            text="Renters insurance"
                            href="/products/renters-insurance"
                            icon="insurance"
                            description="Keep your stuff safe, without breaking the bank"
                          />
                        </li>
                        <li>
                          <SubnavLink
                            text="Lease guarantee"
                            href="/products/lease-guarantee"
                            icon="report-money"
                            description="Let Rhino be your guarantor"
                          />
                        </li>
                      </ul>
                    </SubnavColumn>
                    <SubnavColumn
                      title="Connect with us"
                      isSidebar
                    >
                      <ul>
                        <li>
                          <SubnavLink
                            text="Help center"
                            href="https://support.sayrhino.com/hc/en-us"
                            icon="notebook"
                          />
                        </li>
                        <li>
                          <SubnavLink
                            text="Contact us"
                            href="https://support.sayrhino.com/hc/en-us/articles/360055194951-Email-us"
                            icon="chat"
                          />
                        </li>
                        <li>
                          <SubnavLink
                            text="Blog"
                            href="https://www.sayrhino.com/blog/renters"
                            icon="blockquote"
                          />
                        </li>
                      </ul>
                    </SubnavColumn>
                  </Subnav>
                </DropdownNavItem>
                <DropdownNavItem
                  text="Property Managers"
                  href="/partners"
                  icon="landlords-stroke"
                  className={styles.landlords}
                  isMobile={isMobile}
                  isMobileSubnavActive={activeMobileSubnav === 'landlords'}
                  mobileButtonDescription="Streamline your move-in process with Rhino’s suite of digital tools, integrated directly into your PMS."
                  onMobileButtonClick={() => setActiveMobileSubnav('landlords')}
                >
                  <Subnav>
                    <SubnavColumn
                      title="Overview"
                      className={styles.landlordsOverview}
                    >
                      <a
                        href="/partners"
                        className={styles.overview}
                      >
                        <IconText icon="landlords">Property Managers</IconText>
                        <p>
                          Streamline your move-in process with Rhino’s suite of
                          digital tools, integrated directly into your PMS.
                        </p>
                        <Button
                          tag="div"
                          theme="secondary"
                        >
                          {isMobile
                            ? 'Property managers overview'
                            : 'Why choose Rhino'}
                        </Button>
                      </a>
                    </SubnavColumn>
                    <SubnavColumn className={styles.landlordsProducts}>
                      <strong className={styles.columnTitle}>
                        Partner with us
                      </strong>
                      <ul>
                        <li>
                          <SubnavLink
                            text="Request a demo"
                            href={demoUrl}
                            icon="demo"
                            description="Ready to streamline your move-ins? Get started today."
                          />
                        </li>
                      </ul>
                    </SubnavColumn>
                    <SubnavColumn
                      title="Connect with us"
                      isSidebar
                    >
                      <ul>
                        <li>
                          <SubnavLink
                            text="Help center"
                            href="https://get.sayrhino.com/portal-help-center"
                            icon="notebook"
                          />
                        </li>
                        <li>
                          <SubnavLink
                            text="Contact us"
                            href="https://support.sayrhino.com/hc/en-us/articles/360055194951-Email-us"
                            icon="chat"
                          />
                        </li>
                        <li>
                          <SubnavLink
                            text="Blog"
                            href="/blog/property-owners"
                            icon="blockquote"
                          />
                        </li>
                      </ul>
                    </SubnavColumn>
                  </Subnav>
                </DropdownNavItem>
                <NavItem
                  className={styles.signInItem}
                  text="Sign in"
                  href="/users/sign_in"
                  isButton={isMobile}
                  isBold
                >
                  <span className={styles.signInLabel}>Have an account?</span>
                </NavItem>
                <NavItem
                  className={styles.requestDemoItem}
                  text="Request a demo"
                  href={demoUrl}
                  isButton
                />
              </ul>
            </div>
          </nav>
        </RemoveScroll>
      </FocusLock>
      <button
        className={styles.openButton}
        onClick={() => {
          gsap.globalTimeline.pause();
          setIsMobileMenuOpen(true);
        }}
      >
        <span className="sr">Open menu</span>
        <MenuIcon aria-hidden="true" />
      </button>
    </>
  );
}
