import React from 'react';
import styles from './Start.module.scss';
import { Section } from 'www/components/layout';
import classNames from 'classnames';
import EnrollHero from './EnrollHero';
import EnrollSteps from './EnrollSteps';
import EnrollForm from './EnrollForm';
import { Provider } from 'react-redux';
import store from 'components/v2/signup/redux/store';

export default function Start() {
  return (
    <Section
      className={classNames(styles.wrapper, 'hero-bg')}
      contentClassName={styles.contentWrapper}
    >
      <div className={classNames(styles.formContainer)}>
        <Provider store={store}>
          <EnrollForm />
        </Provider>
      </div>
      <div className={classNames(styles.enrollSteps)}>
        <EnrollHero />
        <EnrollSteps />
      </div>
    </Section>
  );
}
