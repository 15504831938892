import React from 'react';
import styles from './Start.module.scss';

import enrollImgJpg from 'www/images/renters/family-moving-boxes.jpg';
import enrollImgWebp from 'www/images/renters/family-moving-boxes.webp';
import securityDepositImgJpg from 'www/images/renters/security-deposit-options.jpg';
import securityDepositImgWebp from 'www/images/renters/security-deposit-options.webp';
import reviewImgJpg from 'www/images/renters/review.jpg';
import reviewImgWebp from 'www/images/renters/review.webp';

export default function EnrollHero() {
  return (
    <div className={styles.hero}>
      <figure
        className={styles.figureEnroll}
        role="presentation"
      >
        <picture>
          <source
            type="image/webp"
            srcSet={enrollImgWebp}
          />
          <img
            src={enrollImgJpg}
            className="object-cover"
            alt="A mother and father moving boxes into a home with their two young daughters."
            loading="lazy"
          />
        </picture>
      </figure>
      <figure
        className={styles.figureSecurityDeposit}
        role="presentation"
      >
        <picture>
          <source
            type="image/webp"
            srcSet={securityDepositImgWebp}
          />
          <img
            src={securityDepositImgJpg}
            className="object-cover"
            alt="Rhino Security Deposit Options - Security deposit insurance, standard security deposit"
            loading="lazy"
          />
        </picture>
      </figure>
      <figure
        className={styles.figureReview}
        role="presentation"
      >
        <picture>
          <source
            type="image/webp"
            srcSet={reviewImgWebp}
          />
          <img
            src={reviewImgJpg}
            className="object-cover"
            alt="5 star review - Paul Fehrenbach - Very easy process. It helps tremendously when other expenses keep popping up and you would rather hold onto the cash."
            loading="lazy"
          />
        </picture>
      </figure>
    </div>
  )
}
