import React from 'react';
import styles from './Intro.module.scss';
import PartnerLogos from './PartnerLogos';
import { Section, SectionTop, Content } from 'www/components/layout';
import { H2 } from 'www/components/Heading';

export default function Intro() {
  return (
    <Section
      className={styles.wrapper}
      wrapContent={false}
      isCentered
    >
      <Content>
        <SectionTop
          maxWidth={750}
          buttonText="Learn more"
          buttonLink="/about"
        >
          <H2 tagline="Who we empower">
            <span className={styles.line}>
              Financial flexibility for&nbsp;<strong>renters</strong>.
            </span>
            <span className={styles.line}>
              Effortless management for&nbsp;<strong>landlords</strong>.
            </span>
          </H2>
          <p>
            Rhino revolutionizes the rental industry as the first ever deposit
            platform. Our mission is to provide renters with accessible and
            cost-effective deposit choices, all while streamlining the rental
            process for property owners and site teams.
          </p>
        </SectionTop>
      </Content>
      <PartnerLogos />
    </Section>
  );
}
