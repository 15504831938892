import { useState } from 'react';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';
import { isSSR } from 'www/utils/helpers';

export default function useMatchMedia(
  query: string,
  defaultValue: boolean = false
) {
  const [value, setValue] = useState(defaultValue);

  useIsomorphicLayoutEffect(() => {
    if (isSSR()) return;

    const mediaQuery = window.matchMedia(query);

    setValue(mediaQuery.matches);

    const handler = (e: MediaQueryListEvent) => setValue(e.matches);

    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handler);
    } else if (mediaQuery.addListener) {
      // Safari < 14 only supports the deprecated addListener method
      // The deprecation warning can be disabled in VS Code
      // by setting "editor.showDeprecated": false in settings.json
      mediaQuery.addListener(handler);
    }

    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', handler);
      } else if (mediaQuery.removeListener) {
        mediaQuery.removeListener(handler);
      }
    };
  }, [query]);

  return value;
}
