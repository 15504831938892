import React, { useState } from 'react';
import styles from './TestimonialSlider.module.scss';
import Arrow from './arrow.svg';
import BodyText from 'www/components/BodyText';
import classNames from 'classnames';

export default function TestimonialSlider({
  testimonials
}: TestimonialSliderProps) {
  const [activeIndex, setActiveIndex] = useState(0);

  const isFirst = activeIndex === 0;
  const isLast = activeIndex === testimonials.length - 1;

  return !Array.isArray(testimonials) ? null : (
    <div
      className={styles.wrapper}
      role="region"
      aria-label="Testimonials"
      aria-roledescription="carousel"
    >
      <div className={styles.sliderWrapper}>
        {testimonials.length > 1 && (
          <div
            role="group"
            aria-label="Slide controls"
          >
            <div className={classNames(styles.nav, styles.prev)}>
              <button
                className={classNames(styles.navButton, {
                  [styles.disabled]: isFirst
                })}
                disabled={isFirst}
                onClick={() =>
                  setActiveIndex(i => (isFirst ? i : (i - 1 + 4) % 4))
                }
              >
                <Arrow />
                <span className="sr">Previous</span>
              </button>
            </div>

            <div className={classNames(styles.nav, styles.next)}>
              <button
                className={classNames(styles.navButton, {
                  [styles.disabled]: isLast
                })}
                disabled={isLast}
                onClick={() => setActiveIndex(i => (isLast ? i : (i + 1) % 4))}
              >
                <Arrow />
                <span className="sr">Next</span>
              </button>
            </div>
          </div>
        )}

        <div
          className={styles.slider}
          style={{ '--active-index': activeIndex }}
        >
          {testimonials.map(({ text, name, title, photo }, i) => (
            <div
              key={i}
              className={styles.slide}
              role="group"
              aria-roledescription="Slide"
              aria-label={`${i + 1} of ${testimonials.length}`}
            >
              <div className={styles.slideContent}>
                {(text || name || title) && (
                  <div className={styles.slideText}>
                    {text && (
                      <div className={styles.testimonialText}>
                        <BodyText>{text}</BodyText>
                      </div>
                    )}
                    {(name || title) && (
                      <div className={styles.attribution}>
                        {name && (
                          <strong className={styles.name}>{name}</strong>
                        )}
                        {title && <span>{title}</span>}
                      </div>
                    )}
                  </div>
                )}
                {photo && (
                  <div className={styles.photo}>
                    <img
                      src={photo}
                      className="object-cover"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

type TestimonialSliderProps = {
  testimonials: Testimonial[];
};

type Testimonial = {
  name?: string;
  title?: string;
  text: string;
  photo?: string;
};
