import React from 'react';
import SupportSection, {
  type SupportSectionProps
} from 'www/components/SupportSection';

export default function Support() {
  return (
    <SupportSection
      supportItemGroups={supportItemGroups}
      faqs={faqs}
    >
      Whether you prefer exploring self-led materials or seeking assistance from
      knowledgeable professionals, our resources hub is here to answer all your
      questions.
    </SupportSection>
  );
}

const supportItemGroups: SupportSectionProps['supportItemGroups'] = [
  {
    headline: 'Partners',
    items: [
      {
        title: 'Self-guided resources',
        icon: 'bookmark',
        href: 'https://get.sayrhino.com/portal-help-center'
      },
      {
        title: 'Visit our partner help center',
        icon: 'notebook',
        href: 'https://get.sayrhino.com/partner-resource-center'
      },
      {
        title: 'Read our property managers blog',
        icon: 'blockquote',
        href: '/blog/property-owners'
      }
    ]
  },
  {
    headline: 'Renters',
    items: [
      {
        title: 'Visit our renter help center',
        icon: 'notebook',
        href: 'https://support.sayrhino.com/hc/en-us'
      },
      {
        title: 'Read our renters blog',
        icon: 'blockquote',
        href: '/blog/renters'
      }
    ]
  }
];

const faqs: SupportSectionProps['faqs'] = [
  {
    title: 'What products does Rhino offer?',
    content: `Rhino offers security deposit insurance and renters insurance. Our products help you meet your financial needs and move-in requirements.`,
    linkText: 'Read more on our partner help center',
    linkHref: 'https://get.sayrhino.com/partner-resource-center',
    defaultIsOpen: true
  },
  {
    title: 'How does security deposit insurance work?',
    content: `Rhino’s Security Deposit Insurance replaces cash deposits with smart, affordable insurance. Renters pay Rhino a small premium  (instead of a large cash deposit) and the policy secures the home for the property owner in case of damages or unpaid fees.`,
    linkText: 'Read more on our renter help center',
    linkHref:
      'https://support.sayrhino.com/hc/en-us/sections/360009009192-How-Rhino-works',
    defaultIsOpen: true
  },
  {
    title: 'Can I see my Rhino price before I move?',
    content: `Yes, the Rhino application takes less than 60 seconds and eligible renters will always be able to review your pricing options before confirming your partnership with Rhino.`,
    linkText: 'Read more on our renter resource center',
    linkHref:
      'https://support.sayrhino.com/hc/en-us/sections/360009009192-How-Rhino-works'
  },
  {
    title: 'What does Rhino cover?',
    content: `When renters purchase a Rhino policy, it protects their unit the way a security deposit does. Renters are still responsible to pay in the case of excessive damage or unpaid rent.`,
    linkText: 'Read more on our resource center',
    linkHref:
      'https://support.sayrhino.com/hc/en-us/articles/360056603551-What-does-Rhino-Security-Deposit-Insurance-cover'
  }
];
