import React from 'react';
import SupportSection, {
  type SupportSectionProps
} from 'www/components/SupportSection';

export default function Support() {
  return (
    <SupportSection
      supportItems={supportItems}
      faqs={faqs}
    >
      Whether you prefer exploring self-led materials or seeking assistance from
      knowledgeable professionals, our resources hub is here to answer all your
      questions.
    </SupportSection>
  );
}

const supportItems: SupportSectionProps['supportItems'] = [
  {
    title: 'Visit our renter help center',
    icon: 'notebook',
    href: 'https://support.sayrhino.com/hc/en-us'
  },
  {
    title: 'Read our renters blog',
    icon: 'blockquote',
    href: '/blog/renters'
  }
];

const faqs: SupportSectionProps['faqs'] = [
  {
    title: 'How does security deposit insurance work?',
    content: `Rhino’s Security Deposit Insurance replaces cash deposits with smart, affordable insurance. Renters pay Rhino a small premium (instead of a large cash deposit) and the policy secures the home for the property owner in case of damages or unpaid fees.`,
    linkText: 'Read more on our resource center',
    linkHref:
      'https://support.sayrhino.com/hc/en-us/articles/360056603451-What-is-Rhino-Security-Deposit-Insurance',
    defaultIsOpen: true
  },
  {
    title: 'What does security deposit insurance cover?',
    content: `Our policies secure your home for the property owner in case of any damages or unpaid amounts, such as unpaid rent. If a loss is incurred during the course of your lease,   will be responsible for reimbursing Rhino for the approved claim amount. `,
    linkText: 'Read more on our resource center',
    linkHref:
      'https://support.sayrhino.com/hc/en-us/articles/360056603551-What-does-Rhino-Security-Deposit-Insurance-cover'
  },
  {
    title: 'What is Rhino Renters Insurance?',
    content: `Renters insurance is a way for you to protect your home and personal belongings from theft or damage. It provides personal property and liability coverage for you, unlike security deposit insurance which helps you meet your security deposit requirements.`,
    linkText: 'Read more on our resource center',
    linkHref:
      'https://support.sayrhino.com/hc/en-us/articles/4404682463124-What-is-Rhino-s-Renters-Insurance'
  },
  {
    title: 'How does Rhino’s guarantee coverage work?',
    content: `Our guarantee coverage offers security deposit insurance and additional coverage in lieu of having a guarantor co-sign your lease. We work with your property owner to make sure your guarantee coverage meets their requirements.`,
    linkText: 'Read more on our resource center',
    linkHref:
      'https://support.sayrhino.com/hc/en-us/articles/360061185252-What-is-Rhino-guarantor-coverage'
  }
];
