import { useState, useEffect } from 'react';

export default function useIsMobileMenuOpen() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const openHandler = () => setIsMobileMenuOpen(true);
    const closeHandler = () => setIsMobileMenuOpen(false);

    window.addEventListener('rhino:menu-open', openHandler);
    window.addEventListener('rhino:menu-close', closeHandler);

    return () => {
      window.removeEventListener('rhino:menu-open', openHandler);
      window.removeEventListener('rhino:menu-close', closeHandler);
    };
  }, []);

  return isMobileMenuOpen;
}
