import React, { useEffect, MutableRefObject, forwardRef } from 'react';
import styles from './Start.module.scss';
import { CloseIcon } from '@sayrhino/rhino-shared-js';

interface HomePageModalDialogProps {
  closeDialog: () => void;
  children: React.ReactNode|React.ReactNode[];
  header: React.ReactNode;
}

const HomePageModalDialog = forwardRef<HTMLDialogElement, HomePageModalDialogProps>((props, ref) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        props.closeDialog();
      }
    };

    const dialog = (ref as MutableRefObject<HTMLDialogElement | null>).current;
    if (dialog) {
      dialog.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (dialog) {
        dialog.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [ref, props]);

  return (
    <dialog ref={ref}>
      <nav style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <header>{props.header}</header>
        <button
          className={styles.closeDialog}
          onClick={(e) => {
            e.preventDefault();
            props.closeDialog();
          }}
          aria-label="Close dialog"
          title="Close dialog"
        >
          <CloseIcon />
        </button>
      </nav>
      <section>
        {props.children}
      </section>
    </dialog>
  )
});

export default HomePageModalDialog;