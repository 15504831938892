import React from 'react';
import styles from './BrowserButtons.module.scss';
import classNames from 'classnames';

export default function BrowserButtons({ className }: BrowserButtonProps) {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <span className={styles.circle} />
      <span className={styles.circle} />
      <span className={styles.circle} />
    </div>
  );
}

type BrowserButtonProps = {
  className?: string;
};
