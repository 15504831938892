import React from 'react';
import styles from './StatsSection.module.scss';
import { Section, SectionTop } from 'www/components/layout';
import classNames from 'classnames';

export default function StatsSection({
  className,
  children,
  headline,
  tagline,
  sectionTopText
}: StatsSectionProps) {
  return (
    <Section
      className={classNames(styles.wrapper, className)}
      contentClassName={styles.contentWrapper}
    >
      <SectionTop
        // className="content-wrapper"
        headline={headline}
        tagline={tagline}
        isCentered
      >
        {sectionTopText}
      </SectionTop>

      <div className={styles.content}>{children}</div>
    </Section>
  );
}

export function Stat({
  className,
  topLabel,
  bottomLabel,
  value,
  children
}: StatProps) {
  return !value && !children ? null : (
    <div className={classNames(styles.item, className)}>
      {topLabel && <span className={styles.topLabel}>{topLabel}</span>}
      <div className={styles.itemText}>
        {value}
        {children}
      </div>
      {bottomLabel && <span className={styles.bottomLabel}>{bottomLabel}</span>}
    </div>
  );
}

type StatsSectionProps = {
  className?: string;
  children: React.ReactNode;
  headline?: React.ReactNode;
  tagline?: React.ReactNode;
  sectionTopText?: React.ReactNode;
};

type StatProps = {
  className?: string;
  topLabel?: React.ReactNode;
  bottomLabel?: React.ReactNode;
  value?: React.ReactNode;
  children?: React.ReactNode;
};
