import React from 'react';
import { Section, SectionTop } from 'www/components/layout';
import TestimonialSlider from 'www/components/TestimonialSlider';

export default function Testimonials() {
  return (
    <Section>
      <SectionTop
        tagline="Hear from our customers"
        headline="The preferred choice of renters everywhere"
        isCentered
      />
      <TestimonialSlider testimonials={testimonials} />
    </Section>
  );
}

const testimonials = [
  {
    name: 'Jeanne C',
    text: 'Rhino literally saved me from having to call family and friends to borrow $1000 for a security deposit. Rhino made my situation so much better by handling the security deposit and only having me pay a few dollars a month — I wish more rental communities had this option. If I have to relocate again, I will definitely be using rhino again.'
  },
  {
    name: 'Alexus Mitchell',
    text: 'Because of Rhino I didn’t have to pay my full deposit. Rhino saved me over $1000 dollars by allowing me to split my monthly deposit up over a time period of 12 months. Because of this I was able to move into my new place faster and without any hassle.'
  }
];
