import React from 'react';
import styles from './ValueProposition.module.scss';
import { Section, SectionTop } from 'www/components/layout';
import BodyText from 'www/components/BodyText';
import { LottieAnimation } from 'www/components/LottieAnimation';
import classNames from 'classnames';
import SaveTimePoster from './animations/save-time-poster.svg';
import CoveragePoster from './animations/coverage-poster.svg';
import integrationsAnimation from 'www/animations/partners/integrations.lottie';
import saveTimeAnimation from 'www/animations/partners/save-time.lottie';
import coverageAnimation from 'www/animations/partners/coverage.lottie';

export default function ValueProposition() {
  return (
    <Section className={styles.wrapper}>
      <SectionTop
        headline="Automate your deposits. Satisfy your renters"
        isCentered
      >
        Maximize your cash flow, minimize the hassle.
      </SectionTop>
      <div className={styles.itemsWrapper}>
        <Item
          headline="A streamlined process from move-in to move-out"
          text="Rhino integrates with your systems, allowing you to leverage our solutions into your current operations - it all lives in one place, your PMS."
        >
          <LottieAnimation
            poster={<div className={styles.blank} />}
            delay={500}
            src={integrationsAnimation}
            inViewThreshold={0.2}
          />
        </Item>
        <Item
          headline="Save time and lease faster"
          text="Rhino gives renters choice at move-in by offering digital payment options: a low-cost insurance policy* or a cash deposit that can be paid directly with the property management company. By giving renters control of their options, you'll increase your leasing velocity overnight - it's a win win."
        >
          <LottieAnimation
            poster={<SaveTimePoster />}
            delay={500}
            src={saveTimeAnimation}
            fonts={['body-500', 'headline-500']}
            inViewThreshold={0.2}
          />
        </Item>
        <Item
          headline="Get the coverage you need"
          text="Flexible tools that align your cash and insurance requirements for a seamless onboarding process. Collect move in fees, renters insurance and all other requirements in one easy transaction. Get the coverage you need, without losing renters."
        >
          <LottieAnimation
            poster={<CoveragePoster />}
            delay={500}
            src={coverageAnimation}
            // autoplay={false}
            inViewThreshold={0.2}
          />
        </Item>
      </div>
      <div className="disclosure">
        <p>
          * Pricing will vary by individual renter. Actual rates are determined
          based on the specific information provided to Rhino. Monthly payment
          plans may not be available to all renters.
        </p>
      </div>
    </Section>
  );
}

function Item({ headline, text, children }: ItemProps) {
  return (
    <div className={classNames(styles.item, 'text-large')}>
      <div className={styles.text}>
        {headline && <h3>{headline}</h3>}
        <BodyText>{text}</BodyText>
      </div>

      <figure
        className={styles.figure}
        aria-hidden="true"
      >
        {children}
      </figure>
    </div>
  );
}

type ItemProps = {
  headline?: string;
  text?: string;
  children?: React.ReactNode;
};
