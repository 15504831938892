import React from 'react';
import { StatsSection, Stat } from 'www/components/StatsSection';

export default function Stats() {
  return (
    <StatsSection>
      <Stat
        topLabel="Trusted in"
        value="3m"
        bottomLabel="homes nationwide"
      />
      <Stat
        topLabel="Over"
        value="$2 billion"
        bottomLabel="saved for renters"
      />
      <Stat
        topLabel="Used by over"
        value="1M"
        bottomLabel="renters"
      />
      <Stat
        topLabel="Used by"
        value={
          <>
            33
            <span className="small">
              of the
              <br />
              top
            </span>
            100
          </>
        }
        bottomLabel="owners & operators"
      />
    </StatsSection>
  );
}
