import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  memo,
  type ReactNode,
  type MouseEventHandler
} from 'react';
import styles from './Carousel.module.scss';
import useSize from 'www/hooks/useSize';
import { round } from 'www/utils/helpers';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';

export const Carousel = memo(({
  className,
  children,
  fadeOut,
  fadeColor,
  velocity = 30,
  direction = 'left'
}: CarouselProps) => {
  const wrapper = useRef<any>(null);
  const scroller = useRef<HTMLDivElement>(null);
  const timeline = useRef<gsap.core.Timeline | null>(null);
  const { ref } = useInView({
    fallbackInView: true,
    onChange: inView => {
      if (inView) {
        timeline.current?.play();
      } else {
        timeline.current?.pause();
      }
    }
  });

  const setRefs = useCallback(
    (node: HTMLDivElement) => {
      wrapper.current = node;
      ref(node);
    },
    [ref]
  );

  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const checkImages = async () => {
      if (!scroller.current) return;

      const images = scroller.current.querySelectorAll('img');

      if (!images.length) {
        setImagesLoaded(true);
        return;
      }

      const promises: Promise<void>[] = [];

      images.forEach(image => {
        if (!image.complete) {
          promises.push(
            new Promise(resolve => {
              image.onload = () => resolve();
              image.onerror = () => resolve();
            })
          );
        }
      });

      await Promise.all(promises);
      if (isMounted) setImagesLoaded(true);
    };

    checkImages();

    return () => {
      isMounted = false;
    };
  }, []);

  // If the width of the carousel items is less than
  // the width of the wrapper, we duplicate the items to
  // ensure the carousel is always scrolling
  const wrapperRect = useSize(wrapper);

  const [
    hasInitialSize,
    scrollDistance,
    scrollerOffset,
    duplicateCount,
    clonedItems
  ] = useMemo(() => {
    const items: ReactNode[] = [];
    const wrapperWidth = wrapperRect?.width ?? 0;

    if (!wrapperWidth || !scroller.current || !imagesLoaded) {
      return [false, 0, 0, 0, items];
    }

    const children = Array.from(scroller.current.children);

    const elements = children.filter(
      el =>
        el.classList.contains(styles.itemWrapper) &&
        !el.classList.contains('cloned')
    );

    let maxChildWidth = 0;

    const distance = elements.reduce((offset, el) => {
      const width = el.clientWidth;

      if (width > maxChildWidth) {
        maxChildWidth = width;
      }

      return offset + width;
    }, 0);

    const duplicateCount = Math.ceil(wrapperWidth / distance);
    const scrollDistance = distance * (duplicateCount + 1);

    for (let i = 0; i < duplicateCount; i++) {
      elements.forEach((el, j) => {
        const className = el.className
          .split(' ')
          .filter(c => c !== styles.itemWrapper)
          .concat('cloned')
          .join(' ');

        const html = el.querySelector('.carousel-item')?.innerHTML;

        items.push(
          <CarouselItem
            key={`${i}${j}`}
            wrapperClassName={className}
            html={html}
          />
        );
      });
    }

    return [true, scrollDistance, -maxChildWidth, duplicateCount, items];
  }, [wrapperRect?.width, imagesLoaded]);

  useGSAP(
    () => {
      if (!wrapper.current || !scroller.current || !wrapperRect) return;

      const elements = Array.from(
        scroller.current.querySelectorAll(`.${styles.itemWrapper}`)
      );

      if (!elements.length) return;

      elements.reduce((offset, el) => {
        const width = el.clientWidth;
        gsap.set(el, { x: offset, left: -offset });
        return offset + width;
      }, 0);

      gsap.set(scroller.current, { left: scrollerOffset });

      timeline.current = gsap.timeline().to(elements, {
        x: `+=${scrollDistance}`,
        runBackwards: direction !== 'right',
        ease: 'none',
        repeat: -1,
        duration: round(scrollDistance / velocity, 5),
        overwrite: true,
        modifiers: {
          x: gsap.utils.unitize(x => parseFloat(x) % scrollDistance)
        }
      });
    },
    {
      dependencies: [
        wrapperRect,
        duplicateCount,
        wrapper.current,
        scroller.current,
        direction
      ],
      revertOnUpdate: true
    }
  );

  const hoverTimer = useRef<number>();

  const handleMouseEvent: MouseEventHandler<HTMLDivElement> = e => {
    clearTimeout(hoverTimer.current);

    const isHovered = e.type === 'mouseenter';
    const delay = isHovered ? 50 : 0;
    const timeScale = isHovered ? 0 : 1;

    hoverTimer.current = window.setTimeout(() => {
      if (!timeline.current) return;

      gsap.to(timeline.current, {
        timeScale,
        duration: 0.8,
        overwrite: true
      });
    }, delay);
  };

  return (
    <div
      ref={setRefs}
      className={classNames(styles.wrapper, className, {
        [styles.fadeOut]: fadeOut,
        '--fade-color': fadeOut && fadeColor ? fadeColor : undefined
      })}
    >
      <div className={styles.scrollWrapper}>
        <div
          ref={scroller}
          style={{ left: scrollerOffset ? scrollerOffset : undefined }}
          className={classNames(styles.scroller, {
            [styles.hidden]: !hasInitialSize
          })}
          onMouseEnter={handleMouseEvent}
          onMouseLeave={handleMouseEvent}
        >
          {children}
          {clonedItems}
        </div>
      </div>
    </div>
  );
});

export default Carousel;

export function CarouselItem({
  wrapperClassName,
  className,
  children,
  html
}: CarouselItemProps) {
  return (
    <div className={classNames(styles.itemWrapper, wrapperClassName)}>
      <div
        className={classNames('carousel-item', className)}
        dangerouslySetInnerHTML={!html ? undefined : { __html: html }}
      >
        {children}
      </div>
    </div>
  );
}

type CarouselProps = {
  className?: string;
  children?: ReactNode;
  fadeOut?: boolean;
  fadeColor?: string;
  velocity?: number;
  direction?: 'left' | 'right';
};

type CarouselItemProps = {
  wrapperClassName?: string;
  className?: string;
  children?: ReactNode;
  html?: string;
};
