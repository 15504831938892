import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import styles from './Start.module.scss';
import classNames from 'classnames';
import { csrfToken } from 'utils/document';
import PropertyAutocomplete from 'components/common/form/address-input/PropertyAutocomplete';
import { IProperty } from 'types/algolia/property';
import { IAlgoliaHit } from 'types';
import { NullHit } from 'components/common/form/address-input';
import RequiresInvitationDialog from './RequiresInvitationDialog';
import SomethingWentWrongDialog from './SomethingWentWrongDialog';

export default function EnrollForm() {
  const [email, setEmail] = useState('');
  const [property, setProperty] = useState<IProperty>(NullHit);
  const [submittable, setSubmittable] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isInteralErrorDialogOpen, setInternalErrorDialogOpen] = useState(false);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const internalErrDialogRef = useRef<HTMLDialogElement>(null);

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const dialog = dialogRef.current;
    if (!dialog) return;
    isDialogOpen ? dialog.showModal() : dialog.close();
  }, [isDialogOpen]);

  useEffect(() => {
    const dialog = internalErrDialogRef.current;
    if (!dialog) return;
    isInteralErrorDialogOpen ? dialog.showModal() : dialog.close();
  }, [isInteralErrorDialogOpen])

  useEffect(() => {
    setSubmittable(!!email && !!property?.address_line_one);
  }, [email, property]);

  const openInvitationRequiredDialog = () => setIsDialogOpen(true)
  const closeInvitationRequiredDialog = () => setIsDialogOpen(false)

  const openInteralErrorDialog = () => setInternalErrorDialogOpen(true)
  const closeInteralErrorDialog = () => setInternalErrorDialogOpen(false)

  const onChangeProperty = () => {
    if (property?.address_line_one) setProperty(NullHit);
  }

  const onSelectProperty = (property: IAlgoliaHit) => {
    setProperty(property);
  }

  const onEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    const enterKeyCode = 'Enter';
    if (event.key === enterKeyCode) {
      event.stopPropagation();
      formRef.current?.requestSubmit();
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!submittable) return;

    const params = {
      property_id: property.property_id,
      email
    };

    fetch('/start_enrollment', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify(params)
    }).then((response): any => {
      response.json().then(data => {
        if (response.ok) {
          window.location.href = data.redirect_path
        } else {
          if (data.errors?.message === 'requires_invitation') {
            openInvitationRequiredDialog();
          } else {
            openInteralErrorDialog();
          }
        }
      }).catch(() => {
        openInteralErrorDialog();
      });
    })
  }

  return (
    <form ref={formRef} onSubmit={onSubmit}>
      <div className={classNames(styles.headingContainer)}>
        <h1>
          Ready to
          <span style={{ color: '#DD267D' }}>
            &nbsp;explore&nbsp;
          </span>
          Rhino's offerings?
        </h1>
        <h2>
          Let's start by entering some details below.
        </h2>
      </div>

      <label htmlFor="enroll-email">
        Email
        <input
          id="enroll-email"
          type="email"
          placeholder="Enter your email address"
          onChange={({ target }) => {
            setEmail(target.value)
          }}
          onKeyPress={onEnterPress}
        />
      </label>

      <label htmlFor="enroll-address">
        Property Address
        <PropertyAutocomplete
          dataCy={'renterStartAddress'}
          clear={false}
          onChange={onChangeProperty}
          onSelect={onSelectProperty}
          value={property?.address_line_one}
          disabled={false}
          empty={false}
          errors={{ address: '' }}
          autocompleteProps={ {
            placeholder: 'Where are you moving?',
            fieldId: 'enroll-address',
            autoComplete: 'chrome-off'
          }}
          forceRerender={true}
        />
      </label>

      <button id="start-your-application-form-submit-button" disabled={!submittable}>Start your application</button>
      <RequiresInvitationDialog ref={dialogRef} closeDialog={closeInvitationRequiredDialog} />
      <SomethingWentWrongDialog ref={internalErrDialogRef} closeDialog={closeInteralErrorDialog} />
    </form>
  )
}
