import React from 'react';
import styles from './PlatformLabel.module.scss';
import Icon from 'www/components/Icon';
import classNames from 'classnames';

export default function PlatformLabel({
  type,
  className,
  children
}: PlatformLabelProps) {
  const isRenter = type === 'renters';
  const content = children ?? isRenter ? 'Renters' : 'Property Managers';

  return (
    <div className={classNames('platform-label', styles.wrapper, className)}>
      <Icon
        name={isRenter ? 'renters' : 'landlords'}
        className={styles.icon}
        aria-hidden="true"
      />
      {content}
    </div>
  );
}

type PlatformLabelProps = {
  type: 'renters' | 'landlords';
  className?: string;
  children?: React.ReactNode;
};
