import React from 'react';
import styles from './Start.module.scss';
import { H3 } from 'www/components/Heading/Heading';

export default function EnrollSteps() {
  return (
    <div className={styles.steps}>
      <H3 tagline="Helping you every step of the way">
        How to enroll with Rhino
      </H3>
      <ul>
        <li>
          <span>
            {/* checkmark icon */}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M20.7071 5.29289C21.0976 5.68342 21.0976 6.31658 20.7071 6.70711L9.70711 17.7071C9.31658 18.0976 8.68342 18.0976 8.29289 17.7071L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929C3.68342 10.9024 4.31658 10.9024 4.70711 11.2929L9 15.5858L19.2929 5.29289C19.6834 4.90237 20.3166 4.90237 20.7071 5.29289Z" fill="white"/>
            </svg>
          </span>
          <p>Apply with your <br /> Property Manager</p>
        </li>
        <li>
          <span>2</span>
          <p>Fill out your <br /> application</p>
        </li>
        <li>
          <span>3</span>
          <p>Choose deposit <br /> insurance or cash</p>
        </li>
        <li>
          <span>4</span>
          <p>
            Complete payment
            <br />
            and relax
          </p>
        </li>
      </ul>
    </div>
  )
}
