import React, { useMemo } from 'react';
import styles from './Reviews.module.scss';
import { Section } from 'www/components/layout';
import { H2 } from 'www/components/Heading';
import { Carousel, CarouselItem } from 'www/components/Carousel';
import useMatchMedia from 'www/hooks/useMatchMedia';
import StarRating from 'www/components/StarRating';

export default function ReviewsSection({
  className,
  headline,
  tagline
}: ReviewsSectionProps) {
  return (
    <Section
      className={className}
      wrapContent={false}
    >
      <H2
        className="content-wrapper"
        tagline={tagline}
        align="center"
      >
        {headline}
      </H2>
      <ReviewsCarousel />
    </Section>
  );
}

export function ReviewsCarousel() {
  const isMobile = useMatchMedia(`(max-width: 1000px)`);
  const reviewComponents = useMemo(() => {
    return reviews.map((review, index) => (
      <ReviewBox
        key={index}
        name={review.name}
        rating={review.rating}
        review={review.review}
      />
    ));
  }, []);

  return isMobile ? (
    <div className={styles.carousel}>{reviewComponents}</div>
  ) : (
    <Carousel
      className={styles.carousel}
      direction="right"
      velocity={20}
    >
      {reviewComponents.map((review, index) => {
        return <CarouselItem key={index}>{review}</CarouselItem>;
      })}
    </Carousel>
  );
}

export function ReviewBox({ name, rating, review }: ReviewProps) {
  return (
    <div className={styles.review}>
      <StarRating
        className={styles.stars}
        rating={rating}
      />
      <strong className={styles.name}>{name}</strong>
      <p>{review}</p>
    </div>
  );
}

const reviews: Review[] = [
  {
    name: 'Empress B.',
    rating: 5,
    review:
      'This is my first time living on my own; no roommates or parents to help with any bills or security deposits. Rhino made the process of getting my first apartment much more affordable and my move in process easy. I am very thankful for Rhino and the opportunity it provided for me.'
  },
  {
    name: 'Aumberly R.',
    rating: 5,
    review:
      'Rhino was seriously a miracle! My husband interviewed and received a dream job and within a month we were on the lookout for a home to rent. Everything literally fell into place and we found the perfect home. Once we were ready to sign the lease we were floored at having to put down $5000 for a deposit. Rhino literally made it possible for us to move across the country and start a job that our family has sacrificed so much for. We couldn’t be more grateful!'
  },
  {
    name: 'Emily A.',
    rating: 5,
    review:
      'Rhino insurance helped me get my apartment for my family. They offered amazing support and coverage for us all. We really needed a helping hand, and Rhino gave us that chance. 2022 was a rough year for my entire family. We were struggling to get by, and rent on top of everything was insane. Rhino insurance was out light at the end of the tunnel. I will forever appreciate what Rhino has done to help our family in our time of need.'
  },
  {
    name: 'Nick K.',
    rating: 5,
    review:
      'An amazing service! Making renting affordable again without the upfront paywall of 2x rent! Easy to use, flexible options, and most importantly fair to the renter. EVeryone should ask their landlords and management to use Rhino!'
  }
];

type Review = {
  name: string;
  rating: number;
  review: string;
};

type ReviewsSectionProps = {
  className?: string;
  headline?: string;
  tagline?: string;
};

type ReviewProps = {
  name: string;
  rating: number;
  review: string;
};
