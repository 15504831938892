import React, { useId, type SelectHTMLAttributes } from 'react';
import Icon from 'www/components/Icon';
import classNames from 'classnames';

export default function SelectInput({
  className,
  placeholder,
  label,
  error,
  canShowError,
  children,
  required,
  ...restProps
}: TextInputProps) {
  const id = useId();
  const errorId = useId();

  if (required === undefined && placeholder) required = true;

  return (
    <div
      className={classNames('field', className, {
        'has-error': error && canShowError
      })}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <div className="select-wrapper">
        <select
          id={id}
          required={required}
          aria-invalid={!!error && canShowError}
          aria-errormessage={error && canShowError ? errorId : undefined}
          {...restProps}
        >
          {placeholder && (
            <option
              value=""
              disabled
            >
              {placeholder}
            </option>
          )}
          {children}
        </select>
        <Icon
          name="chevron"
          className={'arrow'}
        />
      </div>
      {error && canShowError && (
        <div
          id={errorId}
          className="field-error"
          role="alert"
        >
          <Icon name="alert-circle" /> {error}
        </div>
      )}
    </div>
  );
}

type TextInputProps = SelectHTMLAttributes<HTMLSelectElement> & {
  label?: string;
  placeholder?: string;
  error?: string;
  canShowError?: boolean;
};
