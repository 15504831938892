import React from 'react';
import styles from './Alert.module.scss';
import BodyText from 'www/components/BodyText';
import classNames from 'classnames';

export default function Alert({ type, className, children }: AlertProps) {
  return (
    <div
      role="alert"
      className={classNames('alert-message', styles.wrapper, className, {
        [styles.success]: type === 'success',
        [styles.info]: type === 'info',
        [styles.warning]: type === 'warning',
        [styles.error]: type === 'error'
      })}
    >
      <BodyText>{children}</BodyText>
    </div>
  );
}

type AlertProps = {
  type?: 'success' | 'info' | 'warning' | 'error';
  className?: string;
  children: React.ReactNode;
};
