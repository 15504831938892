import React from 'react';
import styles from './Footer.module.scss';
import { Content } from 'www/components/layout';
import Logo from 'www/images/logo-white.svg';
import Facebook from './facebook.svg';
import Twitter from './twitter.svg';
import Instagram from './instagram.svg';
import LinkedIn from './linkedin.svg';
import classNames from 'classnames';

export default function Footer() {
  return (
    <footer className={styles.wrapper}>
      <Content>
        <div className={styles.row}>
          <a
            href="/"
            className={styles.logo}
          >
            <span className="sr">Rhino</span>
            <Logo aria-hidden="true" />
          </a>
          <ul className={classNames(styles.links, styles.mainLinks)}>
            <li>
              <a href="/about">About us</a>
            </li>
            <li>
              <a href="https://support.sayrhino.com">Help center</a>
            </li>
            <li>
              <a href="https://careers.sayrhino.com">Careers</a>
            </li>
            <li>
              <a href="https://support.sayrhino.com/hc/en-us/categories/360003291332-Contact-us">
                Contact us
              </a>
            </li>
          </ul>

          <ul className={classNames(styles.links, styles.social)}>
            <li>
              <a href="https://www.facebook.com/RhinoLabsInc">
                <span className="sr">Facebook</span>
                <Facebook aria-hidden="true" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/sayrhino">
                <span className="sr">Twitter</span>
                <Twitter aria-hidden="true" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/sayrhino">
                <span className="sr">Instagram</span>
                <Instagram aria-hidden="true" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/rhino-labs">
                <span className="sr">LinkedIn</span>
                <LinkedIn aria-hidden="true" />
              </a>
            </li>
          </ul>
        </div>

        <div className={classNames(styles.row, styles.bottomBar)}>
          <ul className={styles.links}>
            <li>
              <a href="/terms_of_service">Terms of service</a>
            </li>
            <li>
              <a href="http://terms.sayrhino.com">Terms of use</a>
            </li>
            <li>
              <a href="/privacy_policy">Privacy policy</a>
            </li>
            <li>
              <a href="/licenses">Licenses</a>
            </li>
            <li>
              <a href="mailto:security@sayrhino.com">Responsible disclosure</a>
            </li>
            {/* <li>
              <a href="#">Do not sell</a>
            </li> */}
          </ul>
        </div>
      </Content>
    </footer>
  );
}
