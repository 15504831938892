import React from 'react';
import styles from './Hero.module.scss';
import { Section } from 'www/components/layout';
import PlatformLabel from 'www/components/PlatformLabel';
import classNames from 'classnames';

import img1Jpg from 'www/images/renters/family-smiling.jpg';
import img1Webp from 'www/images/renters/family-smiling.webp';
import img2Jpg from 'www/images/renters/family-moving-boxes.jpg';
import img2Webp from 'www/images/renters/family-moving-boxes.webp';
import img3Jpg from 'www/images/renters/couple-moving.jpg';
import img3Webp from 'www/images/renters/couple-moving.webp';
import { NavItem } from 'www/components/Header/Navigation/NavItem';

export default function Hero() {
  return (
    <Section
      className={classNames(styles.wrapper, 'hero-bg')}
      contentClassName={styles.contentWrapper}
    >
      <div className={classNames(styles.text, 'hero-text')}>
        <PlatformLabel type="renters" />
        <h1>Simplify your move-in</h1>
        <p className="text-intro">
          Customized payment solutions for a hassle-free start
        </p>
        <p>
          Whether it's saving on your security deposit or securing your belongings with renters insurance,
          we've got the options to get you approved and moved in quickly.
        </p>
        <NavItem
          id="start-your-application-link"
          text="Start your application"
          href="/renters/start"
          className={styles.startYourApplicationLink}
          as="div"
          isButton
        />
      </div>
      <figure
        className={styles.figure}
        role="presentation"
      >
        <div className={styles.image}>
          <picture>
            <source
              type="image/webp"
              src={img1Webp}
            />
            <img
              src={img1Jpg}
              // fetchPriority="high"
              alt=""
            />
          </picture>
        </div>
        <div className={styles.image}>
          <picture>
            <source
              type="image/webp"
              srcSet={img2Webp}
            />
            <img
              src={img2Jpg}
              // fetchPriority="high"
              alt=""
            />
          </picture>
        </div>
        <div className={styles.image}>
          <picture>
            <source
              type="image/webp"
              srcSet={img3Webp}
            />
            <img
              src={img3Jpg}
              // fetchPriority="high"
              alt=""
            />
          </picture>
        </div>
      </figure>
    </Section>
  );
}
