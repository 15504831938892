import React, { forwardRef } from 'react';
import HomePageModalDialog from './HomePageModalDialog';

interface RequiresInvitationDialogProps {
  closeDialog: () => void;
}

const RequiresInvitationDialog = forwardRef<HTMLDialogElement, RequiresInvitationDialogProps>((props, ref) => {
  return (
    <HomePageModalDialog ref={ref}
      header="Signing up for Rhino at this property requires an invitation"
      closeDialog={props.closeDialog}>
      <p>
        Don't worry! Many applicants receive an email invitation to sign-up for Rhino from their property within
        a day or two of submitting their lease application.
      </p>
      <p>
        If you have other concerns or issues, please reach out to your property's leasing team or contact Rhino
        at&nbsp;
        <a className="bold underline" href={`mailto:support@sayrhino.com`}>
          {'support@sayrhino.com'}
        </a>
      </p>
    </HomePageModalDialog>
  )
});

export default RequiresInvitationDialog;
