import React from 'react';
import styles from './Icon.module.scss';
import classNames from 'classnames';

export default function Icon({
  name,
  viewBox = '0 0 100 100',
  className,
  'aria-hidden': ariaHidden = true,
  ...restProps
}: IconProps) {
  return !name ? null : (
    <svg
      className={classNames(
        'icon-sprite',
        `icon-${name}`,
        styles.icon,
        className,
        {
          [styles.stroke]: name.endsWith('-stroke')
        }
      )}
      viewBox={viewBox}
      aria-hidden={ariaHidden}
      {...restProps}
    >
      <use href={`#icon-${name}`} />
    </svg>
  );
}

export function IconText({
  icon,
  className,
  children,
  ...restProps
}: IconTextProps) {
  return (
    <span
      className={classNames(
        'icon-text',
        `icon-text-${icon}`,
        styles.iconText,
        className,
        {
          [styles.strokeIcon]: icon.endsWith('-stroke')
        }
      )}
    >
      <Icon
        name={icon}
        {...restProps}
      />
      {children}
    </span>
  );
}

type IconProps = SVGProps & {
  name?: string;
};

type IconTextProps = {
  icon: string;
  className?: string;
  children: string | React.ReactNode;
  iconProps?: SVGProps;
};
