import React from 'react';
import styles from './Hero.module.scss';
import Animation from './HeroAnimation';
import { Section, Content } from 'www/components/layout';
import Button from 'www/components/Button';
import Icon from 'www/components/Icon';
import StarRating from 'www/components/StarRating';
import Line from 'www/components/Line';
import classNames from 'classnames';

export default function Hero() {
  return (
    <Section
      className={classNames(styles.wrapper, 'hero-bg')}
      wrapContent={false}
    >
      <div className={styles.contentWrapper}>
        <Content className={styles.content}>
          <div className={styles.intro}>
            <div className={styles.text}>
              <h1>
                Renting made&nbsp;<strong>easy</strong>.
              </h1>
              <p className={styles.title}>
                A streamlined renting experience from move-in to move-out.
              </p>
              <p>
                Rhino instills confidence in renters while providing
                comprehensive coverage for property owners—all within a
                seamlessly integrated platform.
              </p>
            </div>
            <Reviews
              rating={4.6}
              count={2500}
            />
            <Line className={styles.divider} />
            <div className={styles.buttons}>
              <Button
                id="request-a-demo-link"
                href="/partners"
                badgeIcon="landlords"
                badgeText="Landlords"
              >
                Request a demo
              </Button>
              <Button
                id="renters-learn-more-link"
                href="/renters"
                theme="secondary"
                badgeIcon="renters"
                badgeText="Renters"
              >
                Learn more
              </Button>
            </div>
          </div>
          <div className={styles.animation}>
            <Animation />
          </div>
        </Content>
      </div>
      <div className={styles.angles} />
    </Section>
  );
}

function Reviews({ rating, count }: ReviewsProps) {
  const { format } = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });

  return (
    <div className={styles.reviews}>
      <Icon
        name="bullhorn"
        className={styles.reviewsIcon}
      />
      <StarRating
        className={styles.stars}
        rating={rating}
      />
      <span className={styles.reviewsItem}>
        <em>{format(rating)}</em> <span>rating</span>
      </span>
      <span className={styles.reviewsItem}>
        <em>{format(count)}+</em> <span>reviews</span>
      </span>
    </div>
  );
}

type ReviewsProps = {
  rating: number;
  count: number;
};
