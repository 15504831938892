import React, { useState, useId } from 'react';
import styles from './Accordion.module.scss';
import BodyText from 'www/components/BodyText';
import { ArrowButton } from 'www/components/Button';
import classNames from 'classnames';

export default function Accordion({
  className,
  title,
  children,
  content,
  linkText,
  linkHref,
  defaultIsOpen = false
}: AccordionProps) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const [disableHoverColor, setDisableHoverColor] = useState(false);
  const buttonId = useId();
  const contentId = useId();

  return (
    <div
      className={classNames('accordion', styles.wrapper, className, {
        [styles.expanded]: isOpen,
        [styles.noHover]: disableHoverColor
      })}
    >
      <button
        id={buttonId}
        className={styles.title}
        aria-expanded={isOpen}
        aria-controls={contentId}
        onClick={() => {
          setIsOpen(!isOpen);
          setDisableHoverColor(true);
        }}
        onMouseLeave={() => setDisableHoverColor(false)}
      >
        <span className={classNames('dashed-line-bottom', styles.titleText)}>
          {title}
        </span>
        <span className={styles.toggleIcon} />
      </button>
      <div
        id={contentId}
        className={styles.content}
        hidden={!isOpen}
        aria-hidden={!isOpen}
        aria-labelledby={buttonId}
      >
        <BodyText>{content}</BodyText>
        <BodyText>{children}</BodyText>
        {linkText && linkHref && (
          <ArrowButton
            className={styles.link}
            href={linkHref}
          >
            {linkText}
          </ArrowButton>
        )}
      </div>
    </div>
  );
}

export type AccordionProps = {
  className?: string;
  title: string | React.ReactNode;
  children?: React.ReactNode;
  content?: string | React.ReactNode;
  linkText?: string;
  linkHref?: string;
  defaultIsOpen?: boolean;
};
