import React from 'react';
import styles from './SupportSection.module.scss';
import { Section } from 'www/components/layout';
import { H2 } from 'www/components/Heading';
import BodyText from 'www/components/BodyText';
import Accordion, { type AccordionProps } from 'www/components/Accordion';
import Icon from 'www/components/Icon';
import { ArrowButton } from 'www/components/Button';
import classNames from 'classnames';

export default function SupportSection({
  className,
  headline = 'Still have questions?',
  tagline,
  text,
  children,
  supportItemsHeadline = 'Connect with us',
  supportItems,
  supportItemGroups,
  faqs,
  faqLink,
  faqLinkText = 'Explore more FAQs on our help center'
}: SupportSectionProps) {
  return (
    <Section
      className={classNames(styles.wrapper, className)}
      contentClassName={styles.contentWrapper}
    >
      <div className={styles.content}>
        <H2 tagline={tagline}>{headline}</H2>
        <BodyText isLarge>{text}</BodyText>
        <BodyText isLarge>{children}</BodyText>

        {(supportItems?.length || supportItemGroups?.length) && (
          <div className={styles.supportItemsWrapper}>
            {supportItemsHeadline && <h3>{supportItemsHeadline}</h3>}

            {supportItems?.length && (
              <ul className={styles.supportItems}>
                {supportItems.map((props, i) => (
                  <SupportItem
                    key={i}
                    {...props}
                  />
                ))}
              </ul>
            )}

            {supportItemGroups?.length &&
              supportItemGroups.map((group, i) => (
                <div
                  key={i}
                  className={styles.supportItemGroup}
                >
                  {group.headline && <h4>{group.headline}</h4>}
                  <ul className={styles.supportItems}>
                    {group.items.map((props, i) => (
                      <SupportItem
                        key={i}
                        {...props}
                      />
                    ))}
                  </ul>
                </div>
              ))}
          </div>
        )}
      </div>

      {faqs?.length && (
        <div className={styles.faqs}>
          <h3>Frequently asked questions</h3>
          {faqs.map((props, i) => (
            <Accordion
              key={i}
              {...props}
            />
          ))}
          {faqLink && (
            <ArrowButton
              href="/"
              className={styles.faqLink}
            >
              {faqLinkText}
            </ArrowButton>
          )}
        </div>
      )}
    </Section>
  );
}

function SupportItem({
  title,
  className: _className,
  icon,
  description,
  href,
  onlineStatus
}: SupportItemProps) {
  const className = classNames(styles.supportItem, _className, {
    [styles.hasDescription]: !!description
  });

  const content = (
    <>
      <span>
        <strong className={styles.supportItemTitle}>
          <Icon name={icon} />
          <span className={styles.supportItemTitleText}>{title}</span>
          {onlineStatus && (
            <OnlineStatus isOnline={onlineStatus === 'online'} />
          )}
        </strong>
        <BodyText>{description}</BodyText>
      </span>
      <Icon
        name="chevron-right"
        className={styles.arrow}
      />
    </>
  );

  return (
    <li className="dashed-line">
      {href ? (
        <a
          href={href}
          className={className}
        >
          {content}
        </a>
      ) : (
        <div className={className}>{content}</div>
      )}
    </li>
  );
}

function OnlineStatus({ isOnline }: OnlineStatusProps) {
  return (
    <span
      className={classNames(styles.onlineStatus, {
        [styles.online]: isOnline
      })}
    >
      {isOnline ? 'Online' : 'Offline'}
    </span>
  );
}

export type SupportItemGroup = {
  headline: string;
  items: SupportItemProps[];
};

export type SupportSectionProps = {
  className?: string;
  headline?: string;
  tagline?: string;
  text?: TextContent;
  children?: React.ReactNode;
  supportItemsHeadline?: string;
  // supportItems?: SupportItemGroup;
  supportItems?: SupportItemProps[];
  // supportItems?: SupportItemProps[] | SupportItemGroup[];
  supportItemGroups?: SupportItemGroup[];
  faqs?: AccordionProps[];
  faqLink?: string;
  faqLinkText?: string;
};

export type SupportItemProps = {
  title: string | React.ReactNode;
  className?: string;
  icon?: string;
  description?: TextContent;
  href?: string;
  onlineStatus?: 'online' | 'offline';
};

export type OnlineStatusProps = {
  isOnline: boolean;
};
