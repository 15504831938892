import {
  useState,
  useRef,
  useCallback,
  type FormEvent,
  type ChangeEventHandler
} from 'react';
import useIsMounted from './useIsMounted';
import { isSSR, isValidEmail, getCookie } from 'www/utils/helpers';

const endpoint =
  'https://api.hsforms.com/submissions/v3/integration/submit/9205635/8fe0aab5-ff7d-4591-897c-fd8c78894903';

export default function useDemoForm() {
  const [state, setState] = useState<DemoFormState>({
    email: '',
    units: '',
    isLoading: false,
    canShowErrors: false,
    hasSucceeded: false,
    errors: {
      email: '',
      units: '',
      general: ''
    }
  });

  const isMounted = useIsMounted();
  const isSubmitting = useRef(false);
  const hasSucceeded = useRef(false);

  const submit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (isSubmitting.current || hasSucceeded.current) return;

      const { email, units } = state;
      const newState = {
        ...state,
        errors: {
          email: validateField('email', email),
          units: validateField('units', units),
          general: state.errors.general
        },
        canShowErrors: true
      };

      if (newState.errors.email || newState.errors.units) {
        setState(newState);
        return;
      }

      isSubmitting.current = true;
      newState.isLoading = true;

      setState(newState);

      try {
        await fetch(endpoint, {
          method: 'POST',
          body: getRequestBody(email, units),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!isMounted()) return;

        setState(prevState => ({
          ...prevState,
          isLoading: false,
          hasSucceeded: true,
          errors: { email: '', units: '', general: '' }
        }));
      } catch (error) {
        // console.error(error);

        if (!isMounted()) return;

        setState(prevState => ({
          ...prevState,
          isLoading: false,
          hasSucceeded: false,
          errors: {
            ...prevState.errors,
            general: 'Something went wrong, please try again later.'
          }
        }));
      }

      setState(prevState => ({
        ...prevState,
        isLoading: false
      }));
    },
    [state, isMounted]
  );

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> =
    useCallback(e => {
      const { name, value } = e.target;

      if (name !== 'email' && name !== 'units') {
        // console.warn(
        //   'Form change handler should only be used for email and units fields'
        // );
        return;
      }

      setState(prevState => {
        return {
          ...prevState,
          [name]: value,
          errors: {
            ...prevState.errors,
            [name]: validateField(name, value)
          }
        };
      });
    }, []);

  return { state, onChange, submit, setState, unitsList };
}

const unitsList = [
  '150-1,999 units',
  '2,000-7,999 units',
  '8,000-14,999 units',
  '15,000+ units'
];

function validateField(name: 'email' | 'units', value: string) {
  switch (name) {
    case 'email':
      return !isValidEmail(value) ? 'Enter a valid email address' : '';
    case 'units':
      return !value ? 'Select a number of units' : '';
    default:
      return '';
  }
}

function getRequestBody(email: string, units: string) {
  if (isSSR()) return JSON.stringify({});

  const params = new URLSearchParams(window.location.search);

  return JSON.stringify({
    submittedAt: new Date().getTime(),
    fields: [
      {
        objectTypeId: '0-1',
        name: 'email',
        value: email
      },
      {
        objectTypeId: '0-1',
        name: 'website__number_of_units',
        value: units
      },
      {
        objectTypeId: '0-1',
        name: 'utm_campaign',
        value: params.get('utm_campaign') ?? ''
      },
      {
        objectTypeId: '0-1',
        name: 'utm_content',
        value: params.get('utm_content') ?? ''
      },
      {
        objectTypeId: '0-1',
        name: 'utm_source',
        value: params.get('utm_source') ?? ''
      },
      {
        objectTypeId: '0-1',
        name: 'utm_medium',
        value: params.get('utm_medium') ?? ''
      },
      {
        objectTypeId: '0-1',
        name: 'utm_term',
        value: params.get('utm_term') ?? ''
      }
    ],
    context: {
      hutk: getCookie('hubspotutk'),
      pageUri: window.location.href,
      pageName: document.title
    }
  });
}

type DemoFormState = {
  email: string;
  units: string;
  isLoading: boolean;
  canShowErrors: boolean;
  hasSucceeded: boolean;
  errors: {
    email: string;
    units: string;
    general: string;
  };
};
