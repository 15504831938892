import React from 'react';
import classNames from 'classnames';

export default function BodyText({ children, isLarge }: BodyTextProps) {
  return (
    <>
      {React.Children.map(children, child => {
        if (typeof child === 'string') {
          return child
            .trim()
            .split('\n')
            .map((text, i) => {
              text = text.trim();
              return text ? (
                <p
                  key={i}
                  className={classNames({ 'text-large': isLarge }) || undefined}
                >
                  {text}
                </p>
              ) : null;
            });
        }

        if (isLarge && React.isValidElement(child) && child.props) {
          return React.cloneElement(child as React.ReactElement<any>, {
            className: classNames(child.props.className, 'text-large')
          });
        }

        return child;
      })}
    </>
  );
}

export { BodyText };

export const MemoizedBodyText = React.memo(BodyText);

type BodyTextProps = {
  children: TextContent;
  isLarge?: boolean;
};
