import React from 'react';
import styles from './PartnerLogos.module.scss';
import { Carousel, CarouselItem } from 'www/components/Carousel';
import Line from 'www/components/Line';

export default function PartnerLogos() {
  return (
    <div className={styles.wrapper}>
      <h3 className="content-wrapper">
        Trusted by owners & operators of all sizes in every region
      </h3>

      <div className={styles.carouselWrapper}>
        <Line />
        <Carousel
          className={styles.carousel}
          fadeOut
        >
          {logos.map((logo, index) => (
            <CarouselItem key={index}>
              <picture>
                {logo.webpSrc && (
                  <source
                    srcSet={logo.webpSrc}
                    type="image/webp"
                  />
                )}
                <img
                  src={logo.src}
                  alt={logo.alt ?? ''}
                />
              </picture>
            </CarouselItem>
          ))}
        </Carousel>
        <Line />
      </div>
    </div>
  );
}

const logos = [
  {
    src: require('images/partner-logos/brick-and-vine.png'),
    webpSrc: require('images/partner-logos/brick-and-vine.webp'),
    alt: 'Brick and Vine'
  },
  {
    src: require('images/partner-logos/cig.png'),
    webpSrc: require('images/partner-logos/cig.webp'),
    alt: 'Clear Integrity Group'
  },
  {
    src: require('images/partner-logos/emba.png'),
    webpSrc: require('images/partner-logos/emba.webp'),
    alt: 'EMBA Management Group'
  },
  {
    src: require('images/partner-logos/evolve.png'),
    webpSrc: require('images/partner-logos/evolve.webp'),
    alt: 'Evolve Companies'
  },
  {
    src: require('images/partner-logos/haley-residential.png'),
    webpSrc: require('images/partner-logos/haley-residential.webp'),
    alt: 'Haley Residential'
  },
  {
    src: require('images/partner-logos/highmark.png'),
    webpSrc: require('images/partner-logos/highmark.webp'),
    alt: 'Highmark Residential'
  },
  {
    src: require('images/partner-logos/jaxon-texas.png'),
    webpSrc: require('images/partner-logos/jaxon-texas.webp'),
    alt: 'Jaxon Texas Property Management'
  },
  {
    src: require('images/partner-logos/karya.png'),
    webpSrc: require('images/partner-logos/karya.webp'),
    alt: 'Karya Property Management'
  },
  {
    src: require('images/partner-logos/lion-real-estate-group.png'),
    webpSrc: require('images/partner-logos/lion-real-estate-group.webp'),
    alt: 'Lion Real Estate Group'
  },
  {
    src: require('images/partner-logos/lund.png'),
    webpSrc: require('images/partner-logos/lund.webp'),
    alt: 'Lund Company'
  },
  {
    src: require('images/partner-logos/odin.png'),
    webpSrc: require('images/partner-logos/odin.webp'),
    alt: 'Odin Properties'
  },
  {
    src: require('images/partner-logos/river-junction.png'),
    webpSrc: require('images/partner-logos/river-junction.webp'),
    alt: 'River Junction Apartment Homes'
  },
  {
    src: require('images/partner-logos/sandalwood.png'),
    webpSrc: require('images/partner-logos/sandalwood.webp'),
    alt: 'Sandalwood'
  },
  {
    src: require('images/partner-logos/simpson-housing.png'),
    webpSrc: require('images/partner-logos/simpson-housing.webp'),
    alt: 'Simpson Housing'
  },
  {
    src: require('images/partner-logos/tishco.png'),
    webpSrc: require('images/partner-logos/tishco.webp'),
    alt: 'Tishco Properties'
  },
  {
    src: require('images/partner-logos/westdale.png'),
    webpSrc: require('images/partner-logos/westdale.webp'),
    alt: 'Westdale'
  }
];
