import styles from './LottieAnimation.module.scss';
import React, {
  useState,
  useMemo,
  useRef,
  useImperativeHandle,
  useEffect,
  Suspense,
  lazy,
  memo,
  forwardRef
} from 'react';
import useIsVisible from 'www/hooks/useIsVisible';
import useIsWindowResizing from 'www/hooks/useIsWindowResizing';
import { isSSR } from 'www/utils/helpers';
import {
  PlayerEvents,
  PlayMode,
  type DotLottieCommonPlayer,
  type Props as DotLottieProps
} from '@dotlottie/react-player';
import classNames from 'classnames';
import useIsMobileMenuOpen from 'www/hooks/useIsMobileMenuOpen';

const Player = lazy(() => import('./Player'));

export default memo(
  forwardRef<AnimationControls, LottieAnimationProps>(function LottieAnimation(
    {
      poster = null,
      className,
      lottieClassName,
      autoplay = true,
      loop = true,
      delay = 0,
      renderer = 'svg',
      rendererSettings = { progressiveLoad: true },
      playMode = PlayMode.Normal,
      renderInline,
      fonts,
      inViewThreshold,
      onEvent,
      onFrame,
      onError,
      onReady,
      onPlay,
      onPause,
      onStop,
      onComplete,
      onLoopComplete,
      onFreeze,
      ...restProps
    },
    ref
  ) {
    const [animationLoaded, setAnimationLoaded] = useState(false);
    const [fontsLoaded, setFontsLoaded] = useState(!fonts?.length);
    const [hasError, setHasError] = useState(false);
    const animation = useRef<LottiePlayer>(null);
    const isMounted = useRef(true);
    const [canResumePlaying, setCanResumePlaying] = useState(autoplay);
    const isMobileMenuOpen = useIsMobileMenuOpen();
    const isResizing = useIsWindowResizing();
    const [hasCompletedDelay, setHasCompletedDelay] = useState(
      !autoplay || delay <= 0
    );

    useEffect(() => {
      isMounted.current = true;

      return () => {
        isMounted.current = false;
      };
    }, []);

    const { ref: wrapper, isVisible } = useIsVisible({
      threshold: inViewThreshold
    });

    const canPlay = useMemo(() => {
      return !animation.current || !animationLoaded
        ? false
        : isVisible &&
            !isMobileMenuOpen &&
            !isResizing &&
            hasCompletedDelay &&
            canResumePlaying;
    }, [
      animationLoaded,
      isVisible,
      isMobileMenuOpen,
      hasCompletedDelay,
      canResumePlaying,
      isResizing
    ]);

    // Callback in case play() was called with with arguments
    const playCallback = useRef<Nullable<() => void>>(null);

    useEffect(() => {
      if (delay <= 0) return;

      const timeout = setTimeout(() => {
        setHasCompletedDelay(true);
      }, delay);

      return () => clearTimeout(timeout);
    }, [delay]);

    useEffect(() => {
      if (!animation.current || !animationLoaded || !isMounted.current) return;

      if (canPlay) {
        if (playCallback.current) {
          playCallback.current();
          playCallback.current = null;
        } else {
          animation.current.play();
        }
      } else {
        animation.current.pause();
      }
    }, [canPlay, animationLoaded]);

    const handlers: Record<keyof typeof events, LottieEvent | undefined> = {
      onError,
      onReady,
      onPlay,
      onPause,
      onStop,
      onComplete,
      onLoopComplete,
      onFreeze
    } as const;

    const handlersArr = Object.values(handlers);

    const eventHandler = useMemo(() => {
      const hasEvents = !!onEvent || !!onFrame || handlersArr.some(Boolean);

      return !hasEvents && animationLoaded
        ? undefined
        : (eventName: PlayerEvents, params: unknown) => {
            if (!isMounted.current) return;

            if (!animationLoaded) {
              if (eventName === PlayerEvents.Ready) {
                setAnimationLoaded(true);
              }

              if (eventName === PlayerEvents.Error) {
                setHasError(true);
                setAnimationLoaded(true);
              }
            }

            onEvent?.(eventName, params);

            if (eventName === PlayerEvents.Frame) {
              if (onFrame) {
                const { frame, seeker } = params as Record<string, number>;
                onFrame(frame, seeker);
              }

              return;
            }

            handlers[events[eventName]]?.();
          };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationLoaded, onEvent, onFrame, ...handlersArr]);

    useImperativeHandle(
      ref,
      (): AnimationControls => ({
        get player() {
          return animation.current ?? null;
        },

        play: (...args) => {
          setCanResumePlaying(true);

          if (canPlay) {
            animation.current?.play(...args);
            playCallback.current = null;
          } else {
            playCallback.current = () => animation.current?.play(...args);
          }
        },
        goToAndPlay: (...args) => {
          setCanResumePlaying(true);

          if (canPlay) {
            animation.current?.goToAndPlay(...args);
            playCallback.current = null;
          } else {
            playCallback.current = () =>
              animation.current?.goToAndPlay(...args);
          }
        },
        goToAndStop: (...args) => {
          setCanResumePlaying(false);

          animation.current?.goToAndStop(...args);
          playCallback.current = null;
        },
        pause: () => {
          setCanResumePlaying(false);

          animation.current?.pause();
          playCallback.current = null;
        }
      }),
      [canPlay]
    );

    useEffect(() => {
      if (!fonts?.length || isSSR()) return;

      const shouldWaitForFonts = [fonts].flat().some(font => {
        const [type, weight] = font.split('-');
        const family =
          type === 'headline' ? 'Maison Neue Extended' : 'Maison Neue';

        return !document.fonts.check(`${weight} 1rem "${family}"`);
      });

      if (!shouldWaitForFonts) {
        setFontsLoaded(true);
      } else {
        document.fonts.ready.then(() => setFontsLoaded(true));
      }
    }, [fonts]);

    return (
      <div
        ref={wrapper}
        className={classNames('animation-wrapper', styles.wrapper, className, {
          [styles.inline]: renderInline
        })}
      >
        <Suspense fallback={poster}>
          {fontsLoaded && !hasError && (
            <Player
              className={classNames(styles.animation, lottieClassName, {
                [styles.hidden]: !animationLoaded
              })}
              ref={animation}
              autoplay={autoplay && hasCompletedDelay}
              loop={loop}
              renderer={renderer}
              rendererSettings={rendererSettings}
              onEvent={eventHandler}
              playMode={playMode}
              {...restProps}
            />
          )}
          {/* {poster && (!animationLoaded || !fontsLoaded || hasError) && poster} */}
          {poster && fontsLoaded && (!animationLoaded || hasError) && poster}
        </Suspense>
        {fonts?.length && (
          <div className={styles.fonts}>
            {[fonts].flat().map((font, i) => (
              <span
                key={i}
                className={font}
              />
            ))}
          </div>
        )}
      </div>
    );
  })
);

const events: Record<string, keyof LottieAnimationProps> = {
  event: 'onEvent',
  frame: 'onFrame',
  error: 'onError',
  ready: 'onReady',
  play: 'onPlay',
  pause: 'onPause',
  stop: 'onStop',
  complete: 'onComplete',
  loopComplete: 'onLoopComplete',
  freeze: 'onFreeze'
} as const;

export type GenericEvent = (eventName: PlayerEvents, params: unknown) => void;

export type LottieEvent = () => void;

export type FrameEvent = (frame: number, seeker: number) => void;

export type LottiePlayer = DotLottieCommonPlayer | null;

export type LottieAnimationProps = DotLottieProps & {
  poster?: React.ReactNode;
  delay?: number;
  lottieClassName?: string;
  renderInline?: boolean;
  inViewThreshold?: number;
  onEvent?: GenericEvent;
  onFrame?: FrameEvent;
  onError?: LottieEvent;
  onReady?: LottieEvent;
  onPlay?: LottieEvent;
  onPause?: LottieEvent;
  onStop?: LottieEvent;
  onComplete?: LottieEvent;
  onLoopComplete?: LottieEvent;
  onFreeze?: LottieEvent;

  // Lottie has issues displaying font variants that
  // haven't been used elsewhere in the app. This prop
  // can be used to force the loading of specific fonts

  // The Bodymovin After Effects extension can convert text
  // to vectors, but that significantly increases the file size
  fonts?: AnimationFont | AnimationFont[];
};

type PlayParameters = Parameters<DotLottieCommonPlayer['play']>;

export type AnimationControls = {
  player: LottiePlayer;
  play: (...args: PlayParameters) => void;
  goToAndPlay: DotLottieCommonPlayer['goToAndPlay'];
  goToAndStop: DotLottieCommonPlayer['goToAndStop'];
  pause: DotLottieCommonPlayer['pause'];
};

export type AnimationFont =
  | 'headline-300'
  | 'headline-500'
  | 'headline-600'
  | 'headline-700'
  | 'body-300'
  | 'body-400'
  | 'body-500'
  | 'body-600';
