import React, { forwardRef } from 'react';
import HomePageModalDialog from './HomePageModalDialog';

interface SomethingWentWrongDialogProps {
  closeDialog: () => void;
}

const SomethingWentWrongDialog = forwardRef<HTMLDialogElement, SomethingWentWrongDialogProps>((props, ref) => {
  return (
    <HomePageModalDialog ref={ref}
      header="Sorry, something went wrong."
      closeDialog={props.closeDialog}>
      <p>
        Please try again or reach out to your property's leasing team or contact Rhino
        at&nbsp;
        <a className="bold underline" href={`mailto:support@sayrhino.com`}>
          {'support@sayrhino.com'}
        </a>
      </p>
    </HomePageModalDialog>
  )
});

export default SomethingWentWrongDialog;
