import React from 'react';
import styles from './List.module.scss';
import Icon from 'www/components/Icon';
import classNames from 'classnames';

export default function List({ className, children }: ListProps) {
  return (
    <ul className={classNames('list', styles.wrapper, className)}>
      {children}
    </ul>
  );
}

export function ListItem({ className, icon, children }: ListItemProps) {
  const iconComponent =
    icon && icon === 'blank' ? (
      <span className={styles.icon} />
    ) : (
      <Icon
        name={icon}
        className={styles.icon}
      />
    );

  return (
    <li className={classNames('dashed-line', className)}>
      {iconComponent}
      {children}
    </li>
  );
}

type ListProps = {
  children?: React.ReactNode;
  className?: string;
};

type ListItemProps = {
  icon?: 'blank' | string;
  className?: string;
  children?: React.ReactNode;
};
