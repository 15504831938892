import React from 'react';
import SupportSection, {
  type SupportSectionProps
} from 'www/components/SupportSection';

export default function Support() {
  return (
    <SupportSection
      supportItems={supportItems}
      faqs={faqs}
    >
      Whether you prefer exploring self-led materials or seeking assistance from
      knowledgeable professionals, our resources hub is here to answer all your
      questions.
    </SupportSection>
  );
}

const supportItems: SupportSectionProps['supportItems'] = [
  {
    title: 'Self-guided resources',
    icon: 'bookmark',
    href: 'https://get.sayrhino.com/portal-help-center'
  },
  {
    title: 'Visit our partner help center',
    icon: 'notebook',
    href: 'https://get.sayrhino.com/partner-resource-center'
  },
  {
    title: 'Read our property managers blog',
    icon: 'blockquote',
    href: '/blog/property-owners'
  }
];

const faqs: SupportSectionProps['faqs'] = [
  {
    title: 'Does Rhino Integrate with your PMS?',
    content: `Yes, Rhino offers a free, leasing integration that flows seamlessly into your current PMS workflow with the goal of satisfying an approved renter’s deposit digitally while also giving them the opportunity to save money on their up front move in costs.`,
    linkText: 'Read more on our resource center',
    linkHref: 'https://get.sayrhino.com/partner-resource-center',
    defaultIsOpen: true
  },
  {
    title: 'What does a Rhino Security Deposit Insurance policy cover?',
    content: `Rhino protects a property manager in the event a renter causes damages to a unit and in the event a renter misses rent payments. If a covered loss is incurred during the course of a lease, a claim may be filed. Rhino will review the claim, and if it is approved, Rhino will pay the claim up to the coverage amount. Our team will then work with the  renter to reimburse Rhino for the approved claim amount.`,
    linkText: 'Read more on our resource center',
    linkHref: 'https://get.sayrhino.com/partner-resource-center'
  },
  {
    title: 'How much does it cost for me (the partner)?',
    content: `It is entirely free for partners. Our business model drives profit from security deposit insurance and our relationships with our reinsurance partners. We don’t make money off of cash deposit transactions.`,
    linkText: 'Read more on our resource center',
    linkHref: 'https://get.sayrhino.com/partner-resource-center'
  },
  {
    title: 'How to cancel a Rhino policy',
    content: `A Rhino policy can be canceled at any point after purchase if the renter and partner agree the coverage is not needed. If a renter created a policy that will no longer be needed, the coverage can be canceled and any applicable refunds will be issued.`,
    linkText: 'Read more on our resource center',
    linkHref: 'https://get.sayrhino.com/partner-resource-center'
  }
];
