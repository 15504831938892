import React, { forwardRef } from 'react';
import {
  DotLottiePlayer,
  type Props as DotLottieProps
} from '@dotlottie/react-player';
import type { LottiePlayer } from './LottieAnimation';

export default forwardRef<LottiePlayer, DotLottieProps>(function Player(
  props,
  ref
) {
  // (globalThis as any).animation = ref;

  return (
    <DotLottiePlayer
      ref={ref}
      {...props}
    />
  );
});
