import React from 'react';
import BodyText from 'www/components/BodyText';

export default function Disclosures({ children }: DisclosureProps) {
  return (
    <div className="disclosure">
      <BodyText>{children}</BodyText>
    </div>
  );
}

type DisclosureProps = {
  children: TextContent;
};
