// @ts-nocheck

import React, {
  type ComponentPropsWithoutRef,
  type HTMLAttributes
} from 'react';
import styles from './Button.module.scss';
import Arrow from './arrow.svg';
import Icon from 'www/components/Icon';
import Spinner from 'www/components/Spinner';
import classNames from 'classnames';

export default function Button<T extends ValidTags = typeof defaultTag>({
  tag,
  href,
  target,
  ...restProps
}: ButtonProps<T>) {
  return (
    <ButtonInner
      href={href}
      target={target}
      tag={tag ?? (href ? 'a' : 'button')}
      {...restProps}
    />
  );
}

export function ArrowButton<T extends ValidTags = typeof defaultTag>({
  tag,
  href,
  target,
  className,
  children,
  ...restProps
}: ButtonProps<T>) {
  return (
    <ButtonInner
      href={href}
      target={target}
      tag={tag ?? (href ? 'a' : 'button')}
      className={classNames('arrow-button', styles.arrowButton, className)}
      {...restProps}
    >
      {children}
      <Arrow className={styles.arrow} />
    </ButtonInner>
  );
}

export function ButtonWrapper({ className, ...rest }: ButtonWrapperProps) {
  return (
    <div
      className={classNames(styles.buttonWrapper, className)}
      {...rest}
    />
  );
}

export function ButtonInner<T extends ValidTags = typeof defaultTag>({
  tag = defaultTag,
  className: _className,
  theme,
  size,
  badgeIcon,
  badgeText,
  children,
  isLoading,
  ...restProps
}: ButtonProps<T>) {
  const Tag: ValidTags = tag;

  const className = classNames('button', styles.button, size, _className, {
    [styles.secondary]: theme === 'secondary',
    [styles.hasBadge]: !!badgeText,
    [styles.loading]: isLoading
  });

  const content = (
    <>
      {badgeText && (
        <span className={styles.badge}>
          {!badgeIcon ? null : typeof badgeIcon === 'string' ? (
            <Icon name={badgeIcon} />
          ) : (
            badgeIcon
          )}
          {badgeText}
        </span>
      )}
      {children}
    </>
  );

  return (
    <Tag
      className={className}
      {...restProps}
    >
      <span className={styles.text}>{content}</span>
      {isLoading && (
        <span className={styles.spinnerWrapper}>
          <Spinner />
        </span>
      )}
    </Tag>
  );
}

type ValidTags = keyof JSX.IntrinsicElements;

type ButtonProps<T extends ValidTags> = {
  tag?: T | ValidTags;
  theme?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  href?: string;
  target?: string;
  badgeIcon?: string | React.ReactNode;
  badgeText?: string;
  isLoading?: boolean;
} & (ComponentPropsWithoutRef<T> & HTMLAttributes<HTMLOrSVGElement>);

const defaultTag = 'button' as const;

type ButtonWrapperProps = React.HTMLAttributes<HTMLDivElement>;
