import React, { memo } from 'react';
import styles from './PartnersHeroAnimation.module.scss';
import Logo from 'www/images/logo.svg';
import useIsVisible from 'www/hooks/useIsVisible';
import useIsMobileMenuOpen from 'www/hooks/useIsMobileMenuOpen';
import classNames from 'classnames';

export default memo(function PartnersHeroAnimation() {
  const { ref: wrapper, isVisible } = useIsVisible();
  const isMobileMenuOpen = useIsMobileMenuOpen();

  return (
    <div
      ref={wrapper}
      className={classNames(styles.wrapper, {
        [styles.paused]: !isVisible || isMobileMenuOpen
      })}
      aria-hidden="true"
    >
      <div className={styles.navWrapper}>
        <Logo className={styles.logo} />
        <ul className={styles.nav}>
          <li>Dashboard</li>
          <li>Policies</li>
          <li>Deposits</li>
          <li>Deposit Funds</li>
          <li>Claims</li>
          <li>Properties</li>
          <li>Units</li>
          <li>Invitations</li>
          <li>Policy Holders</li>
          <li>Property Owners</li>
        </ul>
      </div>

      <div className={styles.activityFeedWrapper}>
        <div className={styles.search}>Search</div>
        <div className={styles.activityFeed}>
          <strong className={styles.activityFeedTitle}>Activity feed</strong>
          <ActivityItem
            title="Brian AMT Maine"
            description="Brian's ME House"
            status="Active"
            coverageDates="Jul 26, 2023 - Feb 25, 2024"
            coverageAmount="$6,000"
            alertType="error"
            days={7}
          />
          <ActivityItem
            title="Brian AMT Maine"
            description="Brian's ME House"
            status="Upcoming"
            coverageDates="Feb 26, 2024 - Feb 25, 2025"
            coverageAmount="$6,000"
            alertType="warning"
            days={25}
          />
          <ActivityItem
            title="Bilawal Umer"
            titleDetails="1"
            description="Data 17489 Optimization"
            status="Upcoming"
            coverageDates="Feb 26, 2024 - Feb 25, 2025"
            coverageAmount="$600"
            alertType="warning"
            days={26}
          />
          <ActivityItem
            title="Dan Smith"
            description="Dan's CA House"
            status="Upcoming"
            coverageDates="Feb 29, 2024 - Feb 29, 2025"
            coverageAmount="$1,200"
            alertType="warning"
            days={29}
          />
        </div>
      </div>

      <div className={styles.overviewWrapper}>
        <div className={styles.overviewTitle}>Portfolio overview</div>
        <div className={styles.overviewContent}>
          <div className={styles.goal}>
            <span />
          </div>

          <div className={styles.overviewBox}>
            <strong className={styles.overviewBoxTitle}>
              Sign up rate for invitations sent
            </strong>
            <p>
              Renters see the value Rhino brings to them when given the choice
              between a security deposit or Rhino.
            </p>
            <div className={styles.overviewBoxDetails}>15%</div>
          </div>

          <div className={styles.overviewBox}>
            <strong className={styles.overviewBoxTitle}>
              Average time to first renter
            </strong>
            <div className={styles.overviewBoxDetails}>-620 days</div>
          </div>
        </div>
      </div>
    </div>
  );
});

function ActivityItem({
  title,
  titleDetails = 'N/A',
  description,
  status,
  coverageDates,
  coverageAmount,
  alertType,
  days
}: ActivityItemProps) {
  const isError = alertType === 'error';

  const desktopAlertText = isError
    ? 'Policy cancellation requested'
    : 'Review and update information';
  const mobileAlertText = isError
    ? 'Cancellation requested'
    : 'Review information';

  const alertDays = `${days} ${days === 1 ? 'day' : 'days'} left`;

  return (
    <div className={styles.activityItem}>
      <div className={styles.activityContent}>
        <strong className={styles.activityTitle}>
          <span>{title}</span>
          <span>{titleDetails}</span>
        </strong>

        <div className={classNames(styles.activityRow, styles.coverage)}>
          <span>{coverageDates}</span>
          <span>{coverageAmount}</span>
        </div>

        <div className={styles.activityRow}>
          <span>{description}</span>
          <span className={styles.activityStatus}>{status}</span>
        </div>
      </div>

      <div
        className={classNames(styles.activityAlert, {
          [styles.error]: isError
        })}
      >
        {/* <span>{alertText}</span> */}
        <span className={styles.desktopAlertText}>{desktopAlertText}</span>
        <span className={styles.mobileAlertText}>{mobileAlertText}</span>
        <span>{alertDays}</span>
      </div>
    </div>
  );
}

type ActivityItemProps = {
  title: string;
  titleDetails?: string;
  description: string;
  status: string;
  coverageDates: string;
  coverageAmount: string;
  alertType: 'error' | 'warning';
  days: number;
};
